
class Login
{
	
	/**
	 * Constructor
	 */
	constructor()
	{
		var obj = this;
		
		obj.showTwoFA();
		return;
		
		
		if (obj.validate()) return;
		
		$("#reportLoading").show();
		
		$.ajax(
		{
			url: "/services/login.php",
			data: 
			{
				"username": username,
				"password": password
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					if (data.twofa)
					{
						obj.showTwoFA();
					}
					if (data.otp)
					{
						obj.showOTP();
					}
					else if (data.success)
					{
						obj.success();
					}
					else
					{
						Misc.errorMessage("Error Logging In");
					}
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error Logging In");
				
				$("#reportLoading").hide();
			}
		 });
	}
	
	/**
	 * Display 2FA request
	 */
	showTwoFA()
	{
		var obj = this;
		
		// hide login
		$("#loginsection").addClass("d-none");

		// show 2fa
		$("#twofasection").removeClass("d-none");
		
		$("#user_twofa").focus().unbind();
		$("#user_twofa").on("propertychange change paste keyup input", function() 
		{
			var max = 6;
			var code = obj.cleanNumber($(this).val(), max);
			
			$("#user_twofa").val(code);
			
			if (code != "")
			{
				if (code.length == max)
				{
					obj.processTwoFA(code);
				}
			}
		});
	}
	
	/**
	 * Process 2FA users
	 */
	processTwoFA()
	{
		$("#reportLoading").show();
		
		alert("process 2FA");
		return;
		
		$.ajax(
		{
			url: "/services/twofa.php",
			data: 
			{
				"code": code
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					if (data.success)
					{
						obj.success();
					}
					else
					{
						Misc.errorMessage("Error Validating 2FA");
					}
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error Validating 2FA");
				
				$("#reportLoading").hide();
			}
		 });
	}
	
	/**
	 * Display OTP request
	 */
	showOTP()
	{
		var obj = this;
		
		// hide login
		$("#loginsection").addClass("d-none");
		
		// show 2fa
		$("#otpsection").removeClass("d-none");
		
		$("#user_otp").focus().unbind();
		$("#user_otp").on("propertychange change paste keyup input", function() 
		{
			var max = 4;
			var otp = obj.cleanNumber($(this).val(), max);
			
			$("#user_otp").val(otp);
			
			if (otp != "")
			{
				if (otp.length == max)
				{
					obj.processOTP(otp);
				}
			}
		});
	}
	
	/**
	 * Process SMS users
	 */
	processOTP(otp)
	{
		$("#reportLoading").show();
		
		alert("process otp");
		return;
		
		$.ajax(
		{
			url: "/services/otp.php",
			data: 
			{
				"code": otp
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					if (data.success)
					{
						obj.success();
					}
					else
					{
						Misc.errorMessage("Error Validating 2FA");
					}
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error Validating 2FA");
				
				$("#reportLoading").hide();
			}
		 });
	}
	
	/**
	 * Process successful logins
	 */
	success()
	{
		window.location.href = "https://www.altcointrader.co.za";
	}
	
	/**
	 * Basic validation
	 */
	validate()
	{
		var error = "";
		var username = $("#user_name").val();
		
		if (username == "")
		{
			error += "Please enter a Username / Email";
		}
		
		var password = $("#user_password").val();
		if (password == "")
		{
			error += "Please enter a Password";
		}
		
		if (error != "")
		{
			Misc.errorMessage(error);
			return false;
		}
		else
		{
			return true;
		}
	}
	
	/**
	 * Strip all but numbers
	 */
	cleanNumber(value, max)
	{
		// max length
		var cleaned = (value.length >= max) ? value.substr(0, max) : value;
		
		// replace non numbers
		cleaned = cleaned.replace(/[^0-9]/g, "");
		
		return cleaned;
	}
	
}
