
class LineChart
{

	/**
	 * Setup
	 */
	constructor(data, containter)
	{
		// default
		$(containter).html("<div>No Data</div>");
		
		// validate
		if ((data == "") || (data.length == 0) || 
			((data[0]["Data"].length == 0) &&
			(data[1]["Data"].length == 0) &&
			(data[2]["Data"].length == 0) &&
			(data[3]["Data"].length == 0) &&
			(data[4]["Data"].length == 0))
			)
		{
			return;
		}
		
		// setup
		$(containter).html("");
		var obj = this;
		var width = $(containter).width();
		var height = $(containter).height();
		
		this.svg = d3.select(containter).append("svg")
					.attr("width", width)
					.attr("height", height);
		this.margin = {top: 20, right: 30, bottom: 30, left: 50};
		this.width = width - this.margin.left - this.margin.right;
		this.height = height - this.margin.top - this.margin.bottom;
		this.parseTime = d3.timeParse("%Y-%m-%d");
		
		this.minX = 0;
		this.maxX = 0;
		this.minY = new Date();
		this.maxY = 0;
		
		data.forEach(function(d)
		{
			d.Data.forEach(function(e)
			{
				obj.minX = (parseFloat(e.Amount) < parseFloat(obj.minX)) ? e.Amount : obj.minX;
				obj.maxX = (parseFloat(e.Amount) > parseFloat(obj.maxX)) ? e.Amount : obj.maxX;
				
				var dateTime = obj.parseTime(e.Date);
				
				obj.minY = (dateTime < obj.minY) ? dateTime : obj.minY;
				obj.maxY = (dateTime > obj.maxY) ? dateTime : obj.maxY;
			});
		});
		
		this.xScale = d3.scaleTime()
				.domain([Misc.padDate(obj.minY, -1), Misc.padDate(obj.maxY, 1)])
				.range([0, this.width])
				.nice();
		this.yScale = d3.scaleLinear()
				.domain([obj.minX, obj.maxX])
				.range([this.height, 0])
				.nice();
		this.zScale = d3.scaleOrdinal(d3.schemeCategory10);
		
		this.axisGroup = this.svg.append("g")
								.attr("transform", "translate(" + (this.margin.left + 2) + "," + this.margin.top + ")");
		
		this.line = [];
		
		// display
		this.axis(data);

		data.forEach(function(d)
		{
			obj.dots(d.Data, d.Tier);
			obj.lines(d.Data, d.Tier);
		});
	}
	
	/**
	 * Display axis'
	 */
	axis(data)
	{
		this.axisGroup.append("g")
					.attr("transform", "translate(0," + this.height + ")")
					.style("font-size","10px")
					.call(d3.axisBottom(this.xScale));
		
		this.axisGroup.append("g")
					.style("font-size","10px")
					.call(d3.axisLeft(this.yScale));
	}
	
	/**
	 * Display lines
	 */
	lines(data, tier)
	{
		var obj = this;
		
		var lineGroup = this.svg.append("g")
								.attr("transform", "translate(" + 0 + "," + this.margin.top + ")");
		
		this.line.push(d3.line()
				    .x(function(d) { return obj.xScale(new Date(d.Date)); })
				    .y(function(d) { return obj.yScale(d.Amount); })
				    .curve(d3.curveLinear));
	
		lineGroup.append("path")
					.attr("class", "tierOn lineTier lineTier" + tier)
					.style("stroke", function(d) { return obj.zScale(tier); })
					.attr("d", function(d, i) { return obj.line[tier - 1](data); })
					.attr("transform", "translate(" + (this.margin.left + 2) + ", 0)");
		
		var totalLength = lineGroup.selectAll("path").node().getTotalLength();
	
		lineGroup.attr("stroke-dasharray", totalLength + " " + totalLength)
			.attr("stroke-dashoffset", totalLength)
			.transition()
				.delay(function(d, i) { return i * 100; })
				.duration(3000)
				.ease(d3.easeLinear)
				.attr("stroke-dashoffset", 0);
	}
	
	/**
	 * Display dots
	 */
	dots(data, tier)
	{
		var obj = this;
		
		var tooltip = d3.select("body").append("div")	
				    	.attr("class", "tooltip")				
				    	.style("opacity", 0);
	
		this.svg.selectAll("dot")
	        .data(data)
	        .enter()
	        .append("circle")
	        	.attr("class", "tierOn dotTier dotTier" + tier)
	        	.attr("fill", function(d) { return obj.zScale(tier); })
		    	.attr("transform", "translate(" + (this.margin.left + 2) + ", " + this.margin.top + ")")
		    	.style("cursor", "pointer")
		    	.on("mouseover", function(d) 
		    	{
		    		// only show if graph visible
		    		if (d3.select(this).attr("class").indexOf("tierOn") !== -1)
		    		{
			    		tooltip.transition()
			                .duration(200)
			                .style("opacity", .9);
			    		
			    		tooltip.html(d.Date + "<br/>"  + d.Amount)
			                .style("left", (d3.event.pageX) + "px")
			                .style("top", (d3.event.pageY - 28) + "px")
			                .style("background-color", function(d) { return obj.zScale(tier); });
		    		}
		        })
		        .on("mouseout", function(d) 
		        {
		        	tooltip.transition()
		                .duration(200)
		                .style("opacity", 0);
		        })
		        .attr("r", 4)
		        .attr("cx", function(d) { return obj.xScale(new Date(d.Date)); })
		        .attr("cy", function(d) { return obj.height; });
	    
		this.svg.selectAll("circle")
		        .transition()
			        .duration(500)
			        .attr("cy", function(d) { return obj.yScale(d.Amount); });
	}

}