
class Account
{

	/**
	 * Withdrawals History page events
	 */
	withdrawalsHistoryEvents()
	{
		var obj = this;

		// change text color
		$('td').filter(function()
		{
			if ($(this).text() === 'Refunded')
			{
				$(this).css('color','red');
			}
		});

		// show info modal
		$(".withdrawalInfo").click(function()
		{
			var message = "";

			var status = $(this).parent().find(".status").html();
			if (status != "")
			{
				message += "<b>Status</b><br />" + status;
			}

			var comment = $(this).parent().find(".comment").html();
			if (comment != "")
			{
				message += "<br /><br /><b>Comment</b><br />" + comment;
			}

			var address = $(this).data("addr");
			if (address != "")
			{
				message += "<br /><br /><b>Address</b><br />" + address;
			}

			var txid = $(this).data("txid");
			if (txid != "")
			{
				message += "<br /><br /><b>Transaction ID (TXID)</b><br />" + txid;
			}

			var resend = $(this).data("resend");
			if (resend != "")
			{
				message += "<br /><br /><form action=\"withdrawal-history\" method=\"post\"><input name=\"with_id\" type=\"hidden\" value=\"" + resend + "\" /><button class='btn btn-secondary'>Resend Confirmation Email</button></form>";
			}

			$("#modalInfo .modal-body").html(message);
			$("#modalInfo").modal("show");
		});
	}

	/**
	 * Trade History page events
	 */
	tradeHistoryEvents()
	{
		var obj = this;

		// change text color
		$('td').filter(function()
		{
			if ($(this).text() === 'Buy')
			{
				$(this).css('color','green');
			}
			else if ($(this).text() === 'Sell')
			{
				$(this).css('color','red');
			}
		});

		// change coin
		$(".dropdown-menu li").click(function()
		{
			$("#market").val($(this).data("coin"));
			$("#loadMarket").submit();
		});
	}

	/**
	 * Withdrawals page events
	 */
	withdrawalsEvents(ref, id)
	{
		var obj = this;

		// load page
		var url = document.location.toString();

	    if (url.match('#'))
	    {
	    	var type = url.split('#')[1].toUpperCase();

	    	// change tab
	    	switch (type)
	    	{
	    		case "ZAR":
	    			$("#fiatview").tab('show');
	    			$("div [data-target='#fiatview']").addClass("selected");
	    			break;
	    		case "XAU":
	    		case "XAG":
	    			$("#metalsview").tab('show');
	    			$("div [data-target='#metalsview']").addClass("selected");
	    			break;
	    		default:
	    			$("#cryptoview").tab('show');
	    			$("div [data-target='#cryptoview']").addClass("selected");
	    			break;
	    	}

	    	if (type == "XZAR")
	    	{
	    		type = "xZAR";
	    	}

			type = type.replace('_BSC','');
			type = type.replace('_ETH','');
			type = type.replace('_POLY','');

	    	// change card
	        var newCard = $('.withdraw' + type);
	        newCard.addClass("show");

	        // highlight nav
	        $("div [data-target='.withdraw" + type + "']").addClass("selected");
	    }

	    // selected
	    $(document).on('click', '.withdrawgroup div', function()
		{
	    	$('.withdrawgroup div').each(function()
	    	{
	    		$(this).removeClass("selected");
	    	});

			$(this).addClass("selected");
		});

	    $(document).on('click', '.withdrawfiat div', function()
		{
	    	$('.withdrawfiat div').each(function()
	    	{
	    		$(this).removeClass("selected");
	    	});

			$(this).addClass("selected");
		});

	    $(document).on('click', '.withdrawcrypto div', function()
		{
	    	$('.withdrawcrypto div').each(function()
	    	{
	    		$(this).removeClass("selected");
	    	});

			$(this).addClass("selected");
		});

	    $(document).on('click', '.withdrawmetals div', function()
	    {
    	   	$('.withdrawmetals div').each(function()
    	   	{
    	    	$(this).removeClass("selected");
    	    });

	 		$(this).addClass("selected");
	   	});

	    // search
		$(document).on('keyup', '#searchcrypto', function()
		{
			var filter = $(this).val().toUpperCase();

			$('#listcrypto div').each(function()
			{
				if ($(this).html().toUpperCase().indexOf(filter) > -1)
				{
					$(this).show();
				}
				else
				{
					$(this).hide();
				}
			});
		});

		// focus/blur events
		$(document).on("focus", '.amount', function()
		{
			obj.calculateNet($(this));
			// obj.calculateNetInstant($('#instant_withdrawal'));
		});

		$(document).on("focus", '.amount_bsc', function()
		{
			obj.calculateNetBSC($(this));
			// obj.calculateNetInstant($('#instant_withdrawal'));
		});

		$(document).on("focus", '.amount_poly', function()
		{
			obj.calculateNetPOLY($(this));
			// obj.calculateNetInstant($('#instant_withdrawal'));
		});

		$(document).on("focus", '#withdraw_money', function()
		{
			obj.calculateNet($(this));
			obj.calculateNetInstant($('#instant_withdrawal'));
		});

		$(document).on("blur", '.amount', function()
		{
			obj.calculateNet($(this));
			// obj.calculateNetInstant($('#instant_withdrawal'));
		});
		$(document).on("blur", '.amount_bsc', function()
		{
			obj.calculateNetBSC($(this));
			// obj.calculateNetInstant($('#instant_withdrawal'));
		});
		$(document).on("blur", '.amount_poly', function()
		{
			obj.calculateNetPOLY($(this));
			// obj.calculateNetInstant($('#instant_withdrawal'));
		});

		$(document).on("blur", '#withdraw_money', function()
		{
			obj.calculateNet($(this));
			obj.calculateNetInstant($('#instant_withdrawal'));
		});

		$(document).on('input paste change click focus target blur keypress keydown', '#instant_withdrawal', function()
		{
			obj.calculateNetInstant($(this));
		});

		// insert available amount
		$(".amount-src").click(function(e)
		{
			if($(this).closest(".card-body").find(".amount").data("decimals")){
					var decimals = $(this).closest(".card-body").find(".amount").data("decimals");
					var amount = obj.toFixed($(this).data("amount"),decimals);
					$(this).closest(".card-body").find(".amount").val(amount).focus();
			}
			else{
					$(this).closest(".card-body").find(".amount").val($(this).data("amount")).focus();
			}

			obj.validateAmount($(this).closest(".card-body").find(".amount"), $(this).closest(".card-body").find(".amount").get(0));

			e.preventDefault();
		});

		$(".amount-src_bsc").click(function(e)
		{
			if($(this).closest(".card-body").find(".amount_bsc").data("decimals")){
					var decimals = $(this).closest(".card-body").find(".amount_bsc").data("decimals");
					var amount = obj.toFixed($(this).data("amount_bsc"),decimals);
					$(this).closest(".card-body").find(".amount_bsc").val(amount).focus();
			}
			else{
					$(this).closest(".card-body").find(".amount_bsc").val($(this).data("amount")).focus();
			}

			obj.validateAmount($(this).closest(".card-body").find(".amount_bsc"), $(this).closest(".card-body").find(".amount_bsc").get(0));

			e.preventDefault();
		});

		$(".amount-src_poly").click(function(e)
		{
			if($(this).closest(".card-body").find(".amount_poly").data("decimals")){
					var decimals = $(this).closest(".card-body").find(".amount_poly").data("decimals");
					var amount = obj.toFixed($(this).data("amount_poly"),decimals);
					$(this).closest(".card-body").find(".amount_poly").val(amount).focus();
			}
			else{
					$(this).closest(".card-body").find(".amount_poly").val($(this).data("amount")).focus();
			}

			obj.validateAmount($(this).closest(".card-body").find(".amount_poly"), $(this).closest(".card-body").find(".amount_poly").get(0));

			e.preventDefault();
		});

		// validation & confirmation
		$(document).on('input paste change', '.amount', function()
		{
			obj.validateAmount(this, this);
			obj.calculateNet($(this));
			// obj.calculateNetInstant($('#instant_withdrawal'));
		});

		// validation & confirmation
		$(document).on('input paste change', '.amount_bsc', function()
		{
			obj.validateAmount(this, this);
			obj.calculateNetBSC($(this));
			// obj.calculateNetInstant($('#instant_withdrawal'));
		});

		// validation & confirmation
		$(document).on('input paste change', '.amount_poly', function()
		{
			obj.validateAmount(this, this);
			obj.calculateNetPOLY($(this));
			// obj.calculateNetInstant($('#instant_withdrawal'));
		});

		// validation & confirmation
		$(document).on('input paste change', '#withdraw_money', function()
		{
			obj.validateAmount(this, this);
			obj.calculateNet($(this));
			obj.calculateNetInstant($('#instant_withdrawal'));
		});

		$(document).on('input paste change', '.address', function()
		{
			obj.validateAddress(this, this);
		});

		$(document).on('input paste change', '.paymentID', function()
		{
			obj.validatePaymentID(this, this);
		});

		$(document).on('click', '.processWithdrawal', function(e)
		{
			e.preventDefault();

			// check if valid
			var form = $(this).closest("form");
			var canWithdrawal = ($("#withdrawalDetailsComplete").length > 0) ? $("#withdrawalDetailsComplete").val() : 0;
			var amount = obj.validateAmount(form.find(".amount"), form.find(".amount").get(0));
			var address = obj.validateAddress(form.find(".address"), form.find(".address").get(0));
			var paymentID = obj.validatePaymentID(form.find(".paymentID"), form.find(".paymentID").get(0));

			// show incomplete details message
			if (canWithdrawal == 0)
			{
				swal.fire({
					title: "Missing Details",
					text: "Please note withdrawals are not allowed at this moment as your profile is not complete. We require at least your First and Last name (As per your ID/Passport), ID/Passport Number,  and your Cellphone Number.",
					type: "warning",
					showCancelButton: false,
					confirmButtonText: 'Complete Details'
				})
				.then((result) =>
				{
					window.location.href = "/profile";
				});

				return;
			}

			if (amount && address && paymentID)
			{
				// confirm
				swal.fire({
					title: "Make Withdrawal",
					text: "Are you sure you want to make this withdrawal?",
					type: "question",
					showCancelButton: true,
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel'
				})
				.then((result) =>
				{
					if (result.value)
					{
						form.submit();
					}
				});
			}
		});
	}

	toFixed(num, fixed) {
		var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
		return num.toString().match(re)[0];
	}

	/**
	 * Withdrawal Validation
	 */
	validateAmount(elm, dom)
	{
		// ignore empty
		if (dom == null) return true;

		var valid = false;

		if (!dom.checkValidity())
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-exclamation-circle'></i>");

			$(elm).parent().find("small")
			.html("Invalid Amount");
		}
		else if (parseFloat($(elm).data('amount')) < parseFloat($(elm).val()))
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-exclamation-circle'></i>");

			$(elm).parent().find("small")
			.html("Amount is greater than available");
		}
		else if ($(elm).data('fee') && parseFloat($(elm).data('fee')) >= parseFloat($(elm).val()))
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-exclamation-circle'></i>");

			$(elm).parent().find("small")
			.html("Amount is less than or equal to the fee");
		}
		else if (($(elm).data("min") != null) && (parseFloat($(elm).val()) < parseFloat($(elm).data("min"))))
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-exclamation-circle'></i>");

			$(elm).parent().find("small")
			.html("Amount is less than required");
		}
		else
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-check-circle'></i>");

			$(elm).parent().find("small").html("");

			valid = true;
		}

		return valid;
	}

	validateAddress(elm, dom)
	{
		// ignore empty
		if (dom == null) return true;

		var valid = false;

		if(!dom.checkValidity())
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-exclamation-circle'></i>");

			$(elm).parent().find("small")
			.html("Invalid Address");
		}
		else
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-check-circle'></i>");

			$(elm).parent().find("small").html("");

			valid = true;
		}

		return valid;
	}

	validatePaymentID(elm, dom)
	{
		// ignore empty
		if (dom == null) return true;
		if ($(elm).val() == "") return true;

		var valid = false;

		if(!dom.checkValidity())
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-exclamation-circle'></i>");

			$(elm).parent().find("small")
			.html("Invalid Value");
		}
		else
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-check-circle'></i>");

			$(elm).parent().find("small").html("");

			valid = true;
		}

		return valid;
	}

	/**
	 * Misc & Helpers
	 */
	toChecksumAddress(address)
	{
		var address = String(address.toString().toLowerCase().replace('0x',''));
		var hash = keccak256(address).toString('hex');
		var ret = '0x';

		for (var i = 0; i < address.length; i++)
		{
			if (parseInt(hash[i], 16) >= 8)
			{
				ret = ret.concat(address[i].toUpperCase());
			}
			else
			{
				ret = ret.concat(address[i]);
			}
		}

		$('#ethEIP55').text(ret);
	}

	bchAddressConvet()
	{
		var address = $('#bchaddr').text();

		if(address)
		{
			var isLegacyAddress = bchaddr.isLegacyAddress;
			var toLegacyAddress = bchaddr.toLegacyAddress;
			var toCashAddress = bchaddr.toCashAddress;

			if(!isLegacyAddress(address))
			{
			    var newP = '<p>BCH Legacy Format: <strong>'+toLegacyAddress(address)+'</strong></p>';
				$(newP).insertAfter($('#bchaddr_firstP'));
			}
			else
			{
				var newP = '<p>BCH New Address Format: <strong>'+toCashAddress(address)+'</strong></p>';
				$(newP).insertAfter($('#bchaddr_firstP'));
			}
		}
	}

	/**
	 * Withdrawal calculations
	 */
	calculateNet(elm)
	{
		var withdraw = Number(elm.closest(".card-body").find(".amount").val());
		var fee = elm.data("fee");
		var percentage = 0;

		if (elm.data("pfee"))
		{
			percentage = withdraw/100*elm.data("pfee");

			if (percentage > 95)
			{
				percentage = 95;
			}
		}

		var net = withdraw - percentage - fee;
		net = Math.round(net * 100000000) / 100000000;
		net = (net < 0) ? 0 : net;

		var item = elm.closest(".card-body").find(".receive");
		item.html(net + " " + item.data("type"));

		return true;
	}

	calculateNetBSC(elm)
	{
		var withdraw = Number(elm.closest(".card-body").find(".amount_bsc").val());
		var fee = elm.data("fee");
		var percentage = 0;

		if (elm.data("pfee"))
		{
			percentage = withdraw/100*elm.data("pfee");

			if (percentage > 95)
			{
				percentage = 95;
			}
		}

		var net = withdraw - percentage - fee;
		net = Math.round(net * 100000000) / 100000000;
		net = (net < 0) ? 0 : net;

		var item = elm.closest(".card-body").find(".receive_bsc");
		item.html(net + " " + item.data("type"));

		return true;
	}

	calculateNetPOLY(elm)
	{
		var withdraw = Number(elm.closest(".card-body").find(".amount_poly").val());
		var fee = elm.data("fee");
		var percentage = 0;

		if (elm.data("pfee"))
		{
			percentage = withdraw/100*elm.data("pfee");

			if (percentage > 95)
			{
				percentage = 95;
			}
		}

		var net = withdraw - percentage - fee;
		net = Math.round(net * 100000000) / 100000000;
		net = (net < 0) ? 0 : net;

		var item = elm.closest(".card-body").find(".receive_poly");
		item.html(net + " " + item.data("type"));

		return true;
	}

	calculateNetInstant(elm)
				{

					var withdraw = Number($('#withdraw_money').val());


					if(withdraw >= 100000){
						$('#more100k').show();
						$('#less100k').hide();
					}
					else{
						$('#more100k').hide();
						$('#less100k').show();
					}

					var fee = $('#withdraw_money').data("fee");
					var instant_fee = elm.data("instant_fee");
					if(withdraw >= 100000)
					{
						instant_fee = 140
					}
					var percentage = 0;

					// if ($('withdraw_money').data("pfee"))
					// {
						percentage = withdraw/100*$('#withdraw_money').data("pfee");

						if (percentage > 95)
						{
							percentage = 95;
						}
					// }

					var net = withdraw - percentage - fee;
					if(elm.is(":checked")){
						net = net - instant_fee;
						// var item = elm.closest(".card-body").find(".fee");
						// item.append(' + R60');
						if(withdraw >= 100000){
							$('#instant_fee_txt_100k').show();
						}
						else{
							$('#instant_fee_txt').show();
						}
					}
					else{
						$('#instant_fee_txt').hide();
						$('#instant_fee_txt_100k').hide();
					}

					net = Math.round(net * 100000000) / 100000000;
					net = (net < 0) ? 0 : net;


					var item = elm.closest(".card-body").find(".receive");
					item.html(net + " " + item.data("type"));


					// return true;
				}

}
