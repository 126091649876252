
class Security
{

	/**
	 * Constructor
	 */
	Security()
	{
		// determine user fingerprint
		if (window.requestIdleCallback) 
		{
		    requestIdleCallback(function () 
		    {
		        Fingerprint2.get(function (components) 
		        {
		        	var fingerprint = Fingerprint2.x64hash128(components.map(function (pair) { return pair.value }).join(), 31)
		        	save(fingerprint);
		        })
		    })
		} 
		else 
		{
		    setTimeout(function () 
		    {
		        Fingerprint2.get(function (components) 
		        {
		        	var fingerprint = Fingerprint2.x64hash128(components.map(function (pair) { return pair.value }).join(), 31)
		        	save(fingerprint);
		        })  
		    }, 500)
		}
	}
	
	/**
	 * Save fingerprint to db
	 */
	save(fingerprint)
	{
		$.ajax(
		{
			url: "/services/save-fingerprint.php",
			type: "POST",
			data: {
				"fingerprint":fingerprint
			},
			success: function(data) 
			{
				// ignore
			}
		});
	}
	
}
