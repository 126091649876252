
class Tree
{
	
	/**
	 * Build tree and display
	 */
	constructor(container, data, tier)
	{
		var obj = this;
		
		this.tiers = 1;
		this.width = $(container).width();
		this.height = $(container).height();
		this.padLeft = 10;
		this.duration = 500;
		this.colors = d3.scaleOrdinal(d3.schemeCategory10);
		
		// navigate
		this.zoom = d3.zoom()
					.scaleExtent([1, 10])
					.on("zoom", function()
					{
						obj.svg.attr("transform", d3.event.transform);
					});
		
		this.drag = d3.drag()
					.on("start", function(d) 
					{
						d3.event.sourceEvent.stopPropagation();
						d3.select(this).classed("dragging", true);
					})
					.on("drag", function(d) 
					{
						d3.select(this).attr("cx", d.x = d3.event.x).attr("cy", d.y = d3.event.y);
					})
					.on("end", function(d) 
					{
						d3.select(this).attr("cx", d.x = d3.event.x).attr("cy", d.y = d3.event.y);
					});
		
		// container
		this.svg = d3.select(container)
					.append("svg")
					.attr("width", this.width + this.padLeft)
					.attr("height", this.height)
					.call(this.zoom)
					.call(this.drag)
					.append("g")
						.attr("transform", "translate(30, 0)");
		
		// data structure
		this.data = JSON.parse(data);
		this.root = d3.hierarchy(this.data, function(d)
		{
			return d.children;
		});
		
		// tree
		this.tree = d3.tree()
						.size([this.width, this.height])
						.separation(function separation(a, b) 
						{
							return a.parent == b.parent ? 1 : 2;
						});
		this.tree(this.root).each(d => 
		{
			d.y = d.depth * obj.width / (obj.tiers + 1);
		})
		
		if (this.data.children.length == 0)
		{
			$(container).html("<div>No Data</div>");
		}
		else
		{
			// layout
			this.links();
			this.nodes();
		}
	}
	
	/**
	 * Display tier sections
	 */
	sections(totalTiers)
	{
		this.tiers = parseInt(totalTiers);
		
		var obj = this;
		var width = this.width / (this.tiers + 1);
		var sections = [];
		
		for (var i = 1; i <= totalTiers; ++i)
		{
			sections.push(i);
		}
		
		// seperators
		d3.select("g")
			.selectAll("line")
			.data(sections)
			.enter()
				.append("line")
					.attr("class", "sectionSep")
					.attr("x1", function(d, i) { return width * (i + 1); })
					.attr("y1", obj.height)
					.attr("x2", function(d, i) { return width * (i + 1); })
					.attr("y2", 0)
					.style("stroke", function(d, i) { return obj.colors(i + 1); });
		
		// header
		d3.select("g")
			.selectAll(".sectionText")
			.data(sections)
			.enter()
				.append("text")
					.attr("class", "sectionText")
					.attr("opacity", "0.2")
					.attr("dx", 0)
					.attr("dy", 0)
					.text(function(d, i) { return "Tier " + (i + 1); })
					.attr("transform", function(d, i) { return "translate(" + (width * (i + 1) + 80) + ", 650) rotate(270)"; });
		
		this.reload();
	}
	
	/**
	 * Display links
	 */
	links()
	{
		d3.select("g")
			.selectAll(".link")
			.remove();
		
		d3.select("g")
			.selectAll(".link")
			.data(this.root.links())
			.enter()
			.append("path")
				.attr("class", "link")
				.attr("d", d3.linkHorizontal()
							.x(function(d) { return d.y; })
							.y(function(d) { return d.x; })
				);
	}
	
	/**
	 * Display nodes and text
	 */
	nodes()
	{
		var obj = this;
		this.totalNodes = this.root.descendants().length;
		
		// nodes
		d3.select("g")
			.selectAll(".node")
			.remove();
		
		d3.select("g")
			.selectAll(".node")
			.data(this.root.descendants())
			.enter()
			.append("circle")
				.attr("class", function(d) 
				{ 
					var style = "";
					
					if (obj.totalNodes > 1000)
					{
						style = " nodeSmall"; 
					}
					else if (obj.totalNodes > 100)
					{
						style = " nodeMedium";
					}
					
					return (d.data.empty || d.data.code == "" || d.data.code == undefined) ? "node leaf" + style : "node" + style; 
				})
				.attr("cx", function(d) 
				{
					var padding = obj.padLeft;
					
					if (obj.totalNodes > 1000)
					{
						padding = 0; 
					}
					else if (obj.totalNodes > 100)
					{
						padding = 2;
					}
					
					return d.y + padding;
				})
				.attr("cy", function(d) {return d.x;})
				.attr("r", function(d) 
				{
					var size = 8;
					
					if (obj.totalNodes > 1000)
					{
						size = 1; 
					}
					else if (obj.totalNodes > 100)
					{
						size = 2;
					}
					
					return size;
				})
				.style("fill", function(d) { return (d.depth == 0) ? "#c0c0c0" : obj.colors(d.depth); })
				.on("click", function(d) 
		    	{
					var code = (d.data.code == undefined) ? 0 : d.data.code;
					
					// stop unnecessary loads
					if ((d.data.empty) || (code == "") || (d.depth == 5)) return;
					
					if (!d.data.children)
					{
						d.data.children = [];
					}
					
					if (d.data.children.length > 0)
					{
						// hide existing
						d.data._children = d.data.children;
						d.data.children = null;
						
						d._children = d.children;
						d.children = null;
						
						obj.reload();
					}
					else
					{
						if (!d.data._children)
						{
							d.data._children = [];
						}
						
						if (d.data._children.length > 0)
						{
							// show existing
							d.data.children = d.data._children;
							d.data._children = null;
							
							d.children = d._children;
							d._children = null;
							
							obj.reload();
						}
						else
						{
							// dynamically loaded
							var affiliate = new Affiliate();
							affiliate.affiliates(code, d.depth, function(data)
							{
								data = JSON.parse(data);
								
								if (data.children.length == 0)
								{
									d.data.empty = true;
									
									obj.reload();
									return;
								}
								
								if (!d.children)
								{
									d.children = [];
									d.data.children = [];
								}
								
								// create nodes
								for (var i = 0; i < data.children.length; i++)
								{
									var newNode = {
										name: data.children[i].name,
										code: data.children[i].code,
										children: []
									};
		
									var newNode = d3.hierarchy(newNode);
									newNode.depth = d.depth + 1;
									newNode.height = d.height - 1;
									newNode.parent = d;
		
									d.children.push(newNode);
									d.data.children.push(newNode);
								}
								
								obj.reload();
							});
						}
					}
		    	});

		// text
		d3.select("g")
			.selectAll(".nodeText")
			.remove();
		
		d3.select("g")
			.selectAll(".nodeText")
			.data(this.root.descendants())
			.enter()
			.append("text")
				.style("text-anchor", function(d) { return d.children ? "middle" : "start"; })
				.attr("class", function(d) 
				{ 
					var size = "";
					
					if (obj.totalNodes > 1000)
					{
						size = " nodeTextSmall"; 
					}
					else if (obj.totalNodes > 100)
					{
						size = " nodeTextMedium";
					}
					
					return ((d.data.highlight != undefined) && (d.data.highlight == 1)) ? "nodeText nodeTextHighlight" + size : "nodeText" + size; 
				})
				.attr("opacity", "0")
				.attr("dx", function (d) 
				{
					var padding = obj.padLeft;
					
					if (obj.totalNodes > 1000)
					{
						padding = 2; 
					}
					else if (obj.totalNodes > 100)
					{
						padding = 3;
					}
					
					return d.children ? d.y + padding : d.y + padding * 2; 
				})
				.attr("dy", function (d) 
				{ 
					var paddingLeft = 4;
					var paddingTop = 15;
					
					if (obj.totalNodes > 1000)
					{
						paddingLeft = 0; 
						paddingTop = 5;
					}
					else if (obj.totalNodes > 100)
					{
						paddingLeft = 2;
						paddingTop = 4;
					}
					
					return d.children ? d.x - paddingTop : d.x + paddingLeft;
				})
				.text(function(d) 
				{
					// default to unknown
					var details = d.data.name ? d.data.name : "You";
					
					// tiers
					if (d.depth == 0)
					{
						details += " (" + ((d.children.length <= 99) ? d.children.length : "99+") + ")";
					}
					else
					{
						// affiliate
						if (d.data.code != "")
						{
							if (d.children != undefined)
							{
								details += " (" + ((d.children.length <= 99) ? d.children.length : "99+") + ")";
							}
						}
						else
						{
							details += " (0)";
						}
					}
					
					return details;
				})
				.transition()
					.duration(this.duration)
					.attr("opacity", "1");
	}
	
	reload()
	{
		var obj = this;
		
		obj.tree(obj.root).each(d => 
		{
			d.y = d.depth * (obj.width / (obj.tiers + 1));
		})
		
		obj.links();
		obj.nodes();
	}
	
}