
class HighChart
{
	
	/**
	 * Constructor
	 */
	constructor(tradingPair, coinSymbol) 
	{
		$("#chartcontainer").html();
		
		var labelColor = $(".labelColor").css("background-color");
		var volumeColor = $(".volumeColor").css("background-color");
		var candleColor = $(".candleColor").css("background-color");
		var candleUpColor = $(".candleUpColor").css("background-color");
		var candleLineColor = $(".candleLineColor").css("background-color");
		var candleUpLineColor = $(".candleUpLineColor").css("background-color");
		
		$("#reportLoading").show();
		
		Highcharts.setOptions(
		{
			global: {timezoneOffset: -2 * 60}
		});
	
		// set the allowed units for data grouping
		var groupingUnits = [
			["day", [1]],
			["week", [1]],
			["month", [1, 2, 3, 4, 6]]
		];
	
		var hiChartOptions = {
			rangeSelector: {
				inputEnabled: $("#chartcontainer").width() > 480,
				selected: 0,
				enabled: false
			},
			navigator: {
				enabled: false
			},
			chart: {
				// marginBottom: 3,
				// height: 200
			},
			scrollbar: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			exporting: {
				enabled: false
			},
			yAxis: [
				{
					labels: {
						enabled: false
					},
					title: {
						text: "OHLC",
						x: 11
					},
					lineWidth: 2
				},
				{
					labels: {
						align: "left",
						x: 3,
						style: { "color": labelColor, "fontWeight": "none" }
					},
					title: {
						text: "Volume",
						y: 70,
						style: { "color": labelColor, "fontWeight": "bold" }
					},
					height: "100%",
					offset: 0,
					lineWidth: 2,
					opposite: true
				}
			],
			series: [{
				type: "column",
				color: volumeColor,
				name: "Volume",
				yAxis: 1,
				dataGrouping: {
					units: groupingUnits
				}
			},
			{
				type: "candlestick",
				name: coinSymbol.toUpperCase(),
				color: candleColor,
				upColor: candleUpColor,
				lineColor: candleLineColor,
				upLineColor: candleUpLineColor,
				dataGrouping: {
					units: groupingUnits
				}
			}]
		};
	
		const now = Math.trunc(new Date().getTime() / 1000);
		const daySeconds = 36 * 60 * 60;
		
		var to = Math.floor(now / 1800) * 1800 - 1800;
		var from = to - daySeconds;
		
		const symbol = (tradingPair.toUpperCase() != "ZAR") ? tradingPair.toUpperCase() + coinSymbol.toUpperCase() : coinSymbol.toUpperCase();
		
		// live or dev
		var url = "https://www.altcointrader.co.za/api/v3/chart/history?symbol=" + symbol + "&resolution=30&from=" + from + "&to=" + to;
		
		/*
		if (!window.location.href.includes("altcointrader"))
		{
			url = "/data/history.json";
		}
		*/
		
		// grab data
		$.ajax({
			cache: false,
			url,
			dataType: "json",
			success: function(data) 
			{
				// process data
				let ochl = [];
				let volume = [];

				if (data.s == "ok") 
				{
					for (let i = from; i <= to; i += 1800) 
					{
						let index = data.t.indexOf(i);
				        
				        let o = data.o[index];
				        let h = data.h[index];
				        let l = data.l[index];
				        let c = data.c[index];
	
				        let v = data.v[index];
	
				        if (index === -1 && !!ochl.length) 
				        {
				        	o = ochl[ochl.length - 1][4];
				        	h = ochl[ochl.length - 1][4];
				        	l = ochl[ochl.length - 1][4];
				        	c = ochl[ochl.length - 1][4];
				        	v = 0;
				        }
	
				        ochl.push([i * 1000, o, h, l, c]);
				        volume.push([i * 1000, v]);
					}
				}

				let newFrom = (from + (12 * 60 * 60)) * 1000;
			      
				ochl = ochl.filter(value => value[0] > newFrom);
				volume = volume.filter(value => value[0] > newFrom);

				// show chart
				hiChartOptions.series[0].data = volume;
				hiChartOptions.series[1].data = ochl;

				$("#chartcontainer").highcharts("StockChart", hiChartOptions);
				
				$("#reportLoading").hide();
			}
		});
	}

}
