
class Deposit
{

	events(ref, id)
	{
		var obj = this;

		// load page
		var url = document.location.toString();

	    if (url.match('#'))
	    {
	    	var type = url.split('#')[1].toUpperCase();

	    	// change tab
	    	switch (type)
	    	{
	    		case "ZAR":
	    		case "ZAPPER":
	    		case "OZOW":
	    			$("#fiatview").tab('show');
	    			$("div [data-target='#fiatview']").addClass("selected");
	    			break;
	    		case "XAU":
	    		case "XAG":
	    			$("#metalsview").tab('show');
	    			$("div [data-target='#metalsview']").addClass("selected");
	    			break;
	    		default:
	    			$("#cryptoview").tab('show');
	    			$("div [data-target='#cryptoview']").addClass("selected");
	    			break;
	    	}

	    	if (type == "XZAR")
	    	{
	    		type = "xZAR";
	    	}

	    	// change card
	        var newCard = $('.deposit' + type);
	        newCard.addClass("show");

	        // highlight nav
	        $("div [data-target='.deposit" + type + "']").addClass("selected");

	        // change list
	        //$(".custom-dropdown").find(".btn").html($(".custom-dropdown ul").find("[data-target='.deposit" + url.split('#')[1].toUpperCase() + "']").html());
	    }

	    // selected
	    $(document).on('click', '.depositgroup div', function()
		{
	    	$('.depositgroup div').each(function()
	    	{
	    		$(this).removeClass("selected");
	    	});

			$(this).addClass("selected");
		});

	    $(document).on('click', '.depositfiat div', function()
		{
	    	$('.depositfiat div').each(function()
	    	{
	    		$(this).removeClass("selected");
	    	});

			$(this).addClass("selected");
		});

	    $(document).on('click', '.depositcrypto div', function()
		{
	    	$('.depositcrypto div').each(function()
	    	{
	    		$(this).removeClass("selected");
	    	});

			$(this).addClass("selected");
		});

	    $(document).on('click', '.depositmetals div', function()
	    {
    	   	$('.depositmetals div').each(function()
    	   	{
    	    	$(this).removeClass("selected");
    	    });

	 		$(this).addClass("selected");
	   	});

		// search
		$(document).on('keyup', '#searchcrypto', function()
		{
			var filter = $(this).val().toUpperCase();

			$('#listcrypto div').each(function()
			{
				if ($(this).html().toUpperCase().indexOf(filter) > -1)
				{
					$(this).show();
				}
				else
				{
					$(this).hide();
				}
			});
		});

		// processing
		$(document).on('input paste change', '.amount', function()
		{
		    if (obj.validateAmount(this, this))
		    {
		    	$("#gen_zapper").removeAttr("disabled");
		    }
		    else
		    {
		    	$("#gen_zapper").attr("disabled", "true");
		    }

				if($(this).attr("name") == "owzo_dep_amount"){
					obj.calculateFee($(this));
				}
		});

		$(document).on('click', '#gen_zapper', function()
		{
			obj.process(ref, id);
		});

		$(document).on('click', '.close', function()
		{
			$('#modalZapper').modal('hide');
		});
	}

	/**
	 * Withdrawals History page events
	 */
	depositHistoryEvents()
	{
		var obj = this;

		// show info modal
		$(document).on("click", ".depositInfo", function()
		{
			var message = "";

			var comment = $(this).data("comment");
			if (comment != "")
			{
				message += "<b>Comment</b><br />" + comment;
			}

			var address = $(this).data("addr");
			if (address != "")
			{
				message += "<br /><br /><b>Address</b><br />" + address;
			}

			var txid = $(this).data("txid");
			if (txid != "")
			{
				message += "<br /><br /><b>Transaction ID (TXID)</b><br />" + txid;
			}

			$("#modalInfo .modal-body").html(message);
			$("#modalInfo").modal("show");
		});
	}

	calculateFee(elm)
	{
		var depoist = Number(elm.closest(".card-body").find(".amount").val());
		var fee = elm.data("fee");
		var fee_cal_value = 1 - fee;
		var fee_amount = depoist-(depoist*fee_cal_value);
		var fee_percent = fee*100;

		var netFeeAmount = Math.round(fee_amount * 100000000) / 100000000;
		var netFeePercent = Math.round(fee_percent * 100000000) / 100000000;

		var returnVal = 'Fee: ZAR '+netFeeAmount+' ('+netFeePercent+'%)';
		var item = elm.closest(".card-body").find("#fee");
		item.html(returnVal);

		return true;
	}

	validateAmount(elm, dom)
	{
		// ignore empty
		if (dom == null) return true;

		var valid = false;

		if (!dom.checkValidity())
		{
			if($(elm).attr("name") == "owzo_dep_amount"){
				$(elm).parent().parent().find(".input-status")
				.html("<i class='fas fa-exclamation-circle'></i>");

				if(parseFloat($(elm).val()) < 10){
					$(elm).parent().find("small")
					.html("Minimum Amount ZAR 10");
				}
				else if(parseFloat($(elm).val()) > 25000){
					$(elm).parent().find("small")
					.html("Maximum amount ZAR 25000");
				}
				else{
					$(elm).parent().find("small")
					.html("Minimum Amount ZAR 10<br>Maximum amount ZAR 25000");
				}
			}
			else{
				$(elm).parent().parent().find(".input-status")
				.html("<i class='fas fa-exclamation-circle'></i>");

				$(elm).parent().find("small")
				.html("Invalid Amount");
			}
		}
		else if (($(elm).data("min") != null) && (parseFloat($(elm).val()) < parseFloat($(elm).data("min"))))
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-exclamation-circle'></i>");

			$(elm).parent().find("small")
			.html("Amount is less than required");
		}
		else
		{
			$(elm).parent().parent().find(".input-status")
			.html("<i class='fas fa-check-circle'></i>");

			$(elm).parent().find("small").html("");

			valid = true;
		}

		return valid;
	}

	process(ref, id)
	{
		var depAmount = parseFloat($('#zapper_dep_amount').val());

		var zapperSubmitting = false;
		var setZapperSubmitting = function() { zapperSubmitting = true; };

		$('#zapperQRDiv').show();

		window.addEventListener("beforeunload", function (e) {
				if (zapperSubmitting) {
            return undefined;
        }
		    var confirmationMessage = 'You were submitting a Zapper depoist. '
		                            + 'If you leave before the transaction is complete it will not be credited';

		    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
		    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
		});

		zapper("#zapperQR", 37617, 45257, depAmount, ref, function (paymentResult)
		{
			var paymentStatus = paymentResult.status;
			var paymentID = paymentResult.payment.paymentId;
			var zapperID = paymentResult.payment.zapperId;
			var reference = paymentResult.payment.reference;
			var amountPaid = paymentResult.payment.amountPaid;

			var dataString = 'payment_status='+ paymentStatus+
							'&paymentID='+ paymentID+
							'&zapperID='+ zapperID+
							'&reference='+ reference+
							'&amount_paid='+ amountPaid+
							'&user_id='+id;

			console.log(dataString);

			$.ajax({
				type: "POST",
				url: "/ajax/process_zapper_payment.php",
				data: dataString,
				cache: false,
				beforeSend: function(xhr)
				{
					xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
					xhr.setRequestHeader('Access-Control-Allow-Methods', 'POST');
					xhr.setRequestHeader('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
				},
				success: function(result)
				{
				 console.log(result);
				},
				error: function(result)
				{
					console.log(result);
				},
				always: function() {
					setZapperSubmitting();
				}
			});
		});
	}

}
