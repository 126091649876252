
class Affiliate
{
	
	// ---------------------------------------------------------------------------------------------------------------------
	// events
	
	/**
	 * Process main page events
	 */
	mainEvents()
	{
		var obj = this;
		
		// copy to clipboard
		$("#copyCode").click(function()
		{
			Misc.copy($("#affiliateCode").html());
		});
		
		$("#copyLink").click(function()
		{
			Misc.copy($("#affiliateLink").html());
		});
		
		$("#copyBanner").click(function()
		{
			Misc.copy($("#affiliateBannerTA").val());
		});
		
		$("#copyBannerSquare").click(function()
		{
			Misc.copy($("#affiliateBannerSquareTA").val());
		});
		
		$("#copyBannerSkyscraper").click(function()
		{
			Misc.copy($("#affiliateBannerSkyscraperTA").val());
		});
		
		$("#copyBannerSignature").click(function()
		{
			Misc.copy($("#affiliateBannerSignatureTA").val());
		});

		// social shares
		$("#shareTwitter").click(function()
		{
			Misc.openWindow("https://twitter.com/intent/tweet?text=" + encodeURI($(".affiliateShare").html()));
		});
		
		$("#shareFacebook").click(function()
		{
			//Misc.openWindow("https://www.facebook.com/sharer.php?s=100&p[title]=Please+join+my+affiliate+program+on+AltCoinTrader&p[summary]=" + encodeURI($(".affiliateShare").html()) + "&p[url]=" + $("#affiliateLink").html() + "&p[images][0]=");
			Misc.openWindow("https://www.facebook.com/dialog/share?app_id=373738256605452&display=popup&href=" + $("#affiliateLink").html());
		});
		
		$("#shareWhatsApp").click(function()
		{
			Misc.openWindow("https://wa.me/?text=" + encodeURI($(".affiliateShare").html()));
		});
		
		$("#shareEmail").click(function()
		{
			window.open("mailto:?subject=Please join my affiliate program on AltCoinTrader&body=" + $(".affiliateShare").html());
		});

		// terms & conditions
		$("#affiliateTermsAgreed").unbind();
		$("#affiliateTermsAgreed").change(function()
		{
			if (this.checked)
			{
				obj.generateCode();
			}
		});
	}
	
	/**
	 * Process earning events
	 */
	earningsEvents()
	{
		var obj = this;
		
		// change date
		$(".predate").unbind();
		$(".predate").click(function()
		{
			$("#dateFrom").val("");
			$("#dateTo").val("");
			$("#currentPeriod").val($(this).data("period"));
			
			obj.earnings($("#currentTicker").val(), $("#currentPeriod").val(), $("#dateFrom").val(), $("#dateTo").val());
		});
		
		// date ranges
		$("#dateFrom").datepicker({dateFormat: "yy-mm-dd"});
		$("#dateTo").datepicker({dateFormat: "yy-mm-dd"});
		
		$("#customRange").unbind();
		$("#customRange").click(function()
		{
			if (($("#dateFrom").val() == "") || 
				($("#dateTo").val() == ""))
			{
				Misc.errorMessage("Please enter a date range");
				return;
			}
			
			if ($("#dateFrom").val() > $("#dateTo").val())
			{
				Misc.errorMessage("Please enter a valid date range");
				return;
			}
			
			obj.earnings($("#currentTicker").val(), $("#currentPeriod").val(), $("#dateFrom").val(), $("#dateTo").val());
		});
		
		// ticker picker
		$(".dropdown-menu li").click(function()
		{
			if ($(this).data("toggle"))
			{
				$(this).closest(".custom-dropdown").find(".btn").html($(this).html());
			}
			
			obj.earnings($(this).data("coin"), $("#currentPeriod").val(), $("#dateFrom").val(), $("#dateTo").val());
		});
		
		// tiers with dynamic linking
		$(document).on("click", ".tierblock", function()
		{
			var currentTier = $(this).data("tier");
			
			// backgrounds
			$(".tierblock").css({"color" : "#000"});
			
			if (currentTier != 0)
			{
				$(this).css({"color" : $(".bar" + currentTier).attr("fill")});
				
				$(".bar").removeClass("tierOn");
				$(".bar").addClass("tierOff");
				
				$(".bar" + currentTier).removeClass("tierOff");
				$(".bar" + currentTier).addClass("tierOn");
				
				$(".lineTier").removeClass("tierOn");
				$(".lineTier").addClass("tierOff");
				
				$(".lineTier" + currentTier).removeClass("tierOff");
				$(".lineTier" + currentTier).addClass("tierOn");
				
				$(".dotTier").removeClass("tierOn");
				$(".dotTier").addClass("tierOff");
				
				$(".dotTier" + currentTier).removeClass("tierOff");
				$(".dotTier" + currentTier).addClass("tierOn");
			}
			else
			{
				obj.clearTotals();
			}
		});
	}
	
	/**
	 * Process claim events
	 */
	claimsEvents()
	{
		var obj = this;
		
		// ticker picker
		$(".dropdown-menu li").click(function()
		{
			if ($(this).data("toggle"))
			{
				$(this).closest(".custom-dropdown").find(".btn").html($(this).html());
			}
			
			$('.search').blur();
			
			obj.claims($(this).data("coin"));
			obj.claimRequirements($(this).data("coin"));
			obj.currentEarnings($(this).data("coin"));
		});
		
		// create claim
		$("#claimEarnings").unbind();
		$("#claimEarnings").click(function()
		{
			if (!$("#claimEarnings").hasClass("disabled"))
			{
				obj.claimEarnings($("#currentTicker").val());
			}
		});
	}
	
	/**
	 * Process affiliate events
	 */
	affiliateEvents()
	{
		var obj = this;
		
		$("#searchAffiliates").click(function()
		{
			var query = $("#affiliateSearch").val();
			
			if (query != "")
			{
				obj.searchAffiliates(query)
			}
			else
			{
				obj.affiliates("", 1, null);
			}
		});
		
		$("#clearSearchAffiliates").click(function()
		{
			obj.affiliates("", 1, null);
			$("#affiliateSearch").val("");
		});
	}
	
	/**
	 * Remove totals from earnings display
	 */
	clearTotals()
	{
		$(".bar").removeClass("tierOff");
		$(".bar").addClass("tierOn");
		
		$(".lineTier").removeClass("tierOff");
		$(".lineTier").addClass("tierOn");
		
		$(".dotTier").removeClass("tierOff");
		$(".dotTier").addClass("tierOn");
		
		$(".tierblock").css({"color" : "#000"});
	}
	
	// ---------------------------------------------------------------------------------------------------------------------
	// user
	
	/**
	 * Generate affiliate code or pull existing
	 */
	generateCode()
	{
		var obj = this;
		var code = "";
		
		$.ajax(
		{
			url: "/services/affiliate/generate.php",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					if (data.Data != "")
					{
						obj.displayDetails(data.Data);
					}
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
			},
			error: function() 
			{
				Misc.errorMessage("Error Generating Code");
			}
		});
	}
	
	/**
	 * Display commissions
	 */
	displayCommissions()
	{
		$("#reportLoading").show();
		
		$.ajax(
		{
			url: "/services/affiliate/commissions.php",
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					var headers = ["User Tier", "Commission"];
					var table = new Table("#commissions", headers, data.Data, "affiliateCommTbl");
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error Updating Commissions");
				
				$("#reportLoading").hide();
			}
		 });
	}
	
	/**
	 * Display affiliate code, links and banners
	 */
	displayDetails(code)
	{
		if (code != "")
		{
			var affiliateLink = "https://www.altcointrader.co.za/?affiliate=" + code;
			
			$("#affiliateCode").html(code);
			$("#affiliateLink").html(affiliateLink);
			$(".affiliateShare").append(" " + affiliateLink);
			
			$("#affiliateBanner").html("<a href=\"" + affiliateLink + "\"><img src=\"https://www.altcointrader.co.za/images/affiliate/altcointrader728x90.png\" width=\"728\" height=\"90\" alt=\"Altcoin Trader\" class=\"img-fluid\" /></a>");
			$("#affiliateBannerTA").val("<a href=\"" + affiliateLink + "\"><img src=\"https://www.altcointrader.co.za/images/affiliate/altcointrader728x90.png\" width=\"728\" height=\"90\" alt=\"Altcoin Trader\" /></a>");
			$("#affiliateBannerSquare").html("<a href=\"" + affiliateLink + "\"><img src=\"https://www.altcointrader.co.za/images/affiliate/altcointrader336x280.png\" width=\"336\" height=\"280\" alt=\"Altcoin Trader\" class=\"img-fluid\" /></a>");
			$("#affiliateBannerSquareTA").val("<a href=\"" + affiliateLink + "\"><img src=\"https://www.altcointrader.co.za/images/affiliate/altcointrader336x280.png\" width=\"336\" height=\"280\" alt=\"Altcoin Trader\" /></a>");
			$("#affiliateBannerSkyscraper").html("<a href=\"" + affiliateLink + "\"><img src=\"https://www.altcointrader.co.za/images/affiliate/altcointrader160x600.png\" width=\"160\" height=\"600\" alt=\"Altcoin Trader\" class=\"img-fluid\" /></a>");
			$("#affiliateBannerSkyscraperTA").val("<a href=\"" + affiliateLink + "\"><img src=\"https://www.altcointrader.co.za/images/affiliate/altcointrader160x600.png\" width=\"160\" height=\"600\" alt=\"Altcoin Trader\" /></a>");
			$("#affiliateBannerSignature").html("<a href=\"" + affiliateLink + "\"><img src=\"https://www.altcointrader.co.za/images/affiliate/altcointrader300x100.png\" width=\"300\" height=\"100\" alt=\"Altcoin Trader\" class=\"img-fluid\" /></a>");
			$("#affiliateBannerSignatureTA").val("<a href=\"" + affiliateLink + "\"><img src=\"https://www.altcointrader.co.za/images/affiliate/altcointrader300x100.png\" width=\"300\" height=\"100\" alt=\"Altcoin Trader\" /></a>");
			
			$("#affiliateDetails").show();
			$("#affiliateLegal").hide();
		}
	}
	
	// ---------------------------------------------------------------------------------------------------------------------
	// earnings
	
	/**
	 * Earnings pulled from service
	 */
	earnings(ticker, period, from, to)
	{
		var obj = this;
		
		$("#reportLoading").show();
		this.clearTotals();
		
		$.ajax(
		{
			url: "/services/affiliate/earnings.php",
			data: 
			{
				"ticker": ticker,
				"period": period,
				"from": from,
				"to": to
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					obj.displayEarnings(data.Data);
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error Updating Earnings");
				
				$("#reportLoading").hide();
			}
		 });
	}
	
	/**
	 * Display earnings dashboard
	 */
	displayEarnings(data)
	{
		// totals per tier
		var json = JSON.parse(data);
		var total = 0;
		
		for (var i = 0; i < json.length; ++i)
		{
			var amounts = 0;
			
			for (var j = 0; j < json[i]["Data"].length; ++j)
			{
				amounts += parseFloat(json[i]["Data"][j]["Amount"]);
				total += parseFloat(json[i]["Data"][j]["Amount"]);
			}
			
			amounts = (amounts > 0) ? amounts : 0;
			$("#tierTotal" + json[i]["Tier"]).html(amounts.toFixed(8));
		}
		
		// final total
		total = (total > 0) ? total : 0;
		$("#tierTotal").html(total.toFixed(8));
		
		// totals over time
		var barChart = new BarChart(json, "#barchart");
		
		// tiers over time
		var lineChart = new LineChart(json, "#linechart");
	}
	
	/**
	 * Display earnings tiers pulled from service
	 */
	earningsTiers()
	{
		var deferred = $.Deferred();
		
		$.ajax(
		{
			url: "/services/affiliate/tiers.php",
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					var total = data.Data;
					var blocks = "";
					
					for (var i = 1; i <= total; ++i)
					{
						blocks += "<div class='col tierblock m-1' data-tier=" + i + "><div class='tiervalue' id='tierTotal" + i + "'>0.00000000</div><div class='tiertitle'>Tier " + i + "</div></div>";
					}
					
					blocks += "<div class='col tierblock m-1' data-tier='0'><div class='tiervalue' id='tierTotal'>0.00000000</div><div class='tiertitle'>Total</div></div>";
					
					$("#dashboadSummary").html(blocks);
					
					deferred.resolve();
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
					deferred.reject();
				}
			},
			error: function() 
			{
				Misc.errorMessage("Error Reading Tiers");
				deferred.reject();
			}
		 });
		
		return deferred.promise();
	}
	
	// ---------------------------------------------------------------------------------------------------------------------
	// claims
	
	/**
	 * Check if claim button should be shown
	 */
	claimAvailable()
	{
		// disable by default
		if ($("#claimEarnings.disabled").length == 0)
		{
			$("#claimEarnings").addClass("disabled");
		}
		
		var amount = $("#currentEarnings").data("amount");
		if (amount != "")
		{
			amount = parseFloat(amount.replace(",", ""));
		}
		else
		{
			amount = 0;
		}
		
		var requirement = $("#claimRequirement").data("amount");
		if (requirement != "")
		{
			requirement = parseFloat(requirement.replace(",", ""));
		}
		else
		{
			requirement = 0;
		}
		
		// enable button
		if ((amount > 0) && (amount >= requirement))
		{
			$("#claimEarnings").removeClass("disabled");
		}
	}
	
	/**
	 * Current earnings pulled from service
	 */
	currentEarnings(ticker)
	{
		var obj = this;
		var deferred = $.Deferred();
		
		// reset
		$("#currentEarnings").data("amount", 0);
		
		$("#reportLoading").show();
		
		$.ajax(
		{
			url: "/services/affiliate/current-earnings.php",
			data: 
			{
				"ticker": ticker
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					$("#currentEarnings").html(data.Data);
					$("#currentEarnings").data("amount", data.Data);
					obj.claimAvailable();
					deferred.resolve();
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
					deferred.reject();
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error Updating Current Earnings");
				deferred.reject();
				
				$("#reportLoading").hide();
			}
		 });
		
		return deferred.promise();
	}
	
	/**
	 * Claims pulled from service
	 */
	claims(ticker)
	{
		$("#reportLoading").show();
		
		$.ajax(
		{
			url: "/services/affiliate/claims.php",
			data: 
			{
				"ticker": ticker
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					var headers = ["Date", "Amount", "Status"];
					var table = new Table("#earnings", headers, data.Data);
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error Updating Claims");
				
				$("#reportLoading").hide();
			}
		 });
	}
	
	/**
	 * Claim requirements pulled from service
	 */
	claimRequirements(ticker)
	{
		var obj = this;
		var deferred = $.Deferred();
		
		// reset
		$("#claimRequirement").data("amount", 0);
		
		$.ajax(
		{
			url: "/services/affiliate/claim-requirements.php",
			data: 
			{
				"ticker": ticker
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					$("#claimRequirement").html(data.RandAmount + " ZAR / " + data.CoinAmount + " " + ticker);
					$("#claimRequirement").data("amount", data.CoinAmount);
					obj.claimAvailable();
					deferred.resolve();
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
					deferred.reject();
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error Updating Claim Requirements");
				deferred.reject();
				
				$("#reportLoading").hide();
			}
		 });
		
		return deferred.promise();
	}
	
	/**
	 * Claim available earnings
	 */
	claimEarnings(ticker)
	{
		var obj = this;
		
		$.ajax(
		{
			url: "/services/affiliate/create-claim.php",
			data: 
			{
				"ticker": ticker
			},
			type: "POST",
			success: function(data) 
			{
				if (data.Success)
				{
					// show success
					Misc.message("Success", "Earnings claimed, awaiting processing");
					
					// update earnings & table
					obj.currentEarnings($("#currentTicker").val());
					obj.claims($("#currentTicker").val());
					
					// disable button
					$("#claimEarnings").addClass("disabled");
				}
				else
				{
					Misc.errorMessage("Error Claiming Earnings. " + data.Error);
				}
			},
			error: function() 
			{
				Misc.errorMessage("Error Claiming Earnings");
			}
		 });
	}
	
	// ---------------------------------------------------------------------------------------------------------------------
	// affiliates
	
	/**
	 * Display affiliates pulled from service
	 */
	affiliates(code, tier, callback)
	{
		var obj = this;
		$("#reportLoading").show();
		
		$.ajax(
		{
			url: "/services/affiliate/my-affiliates.php",
			data: 
			{
				"code": code
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					if (callback == null)
					{
						var tree = new Tree("#affiliateListing", data.Data, tier);
						obj.sectionTiers(tree);
					}
					else
					{
						callback(data.Data);
					}
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error Updating Affiliates");
				
				$("#reportLoading").hide();
			}
		 });
	}
	
	/**
	 * Search affiliates by name
	 */
	searchAffiliates(search)
	{
		var obj = this;
		$("#reportLoading").show();
		
		$.ajax(
		{
			url: "/services/affiliate/search-affiliates.php",
			data: 
			{
				"search": search
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					var result = JSON.parse(data.Data);
					
					if (result.children != "")
					{
						var tree = new Tree("#affiliateListing", data.Data, 1);
						obj.sectionTiers(tree);
					}
					else
					{
						Misc.errorMessage("'" + search + "' Not Found");
					}
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error Search Affiliates");
				
				$("#reportLoading").hide();
			}
		 });
	}
	
	/**
	 * Display earnings tiers pulled from service
	 */
	sectionTiers(tree)
	{
		$.ajax(
		{
			url: "/services/affiliate/tiers.php",
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					tree.sections(data.Data);
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
			},
			error: function() 
			{
				Misc.errorMessage("Error Reading Tiers");
			}
		 });
	}
	
	/**
	 * Display accuracy of affiliate data
	 */
	affiliateAccuracy()
	{
		var obj = this;
		var deferred = $.Deferred();
		
		$.ajax(
		{
			url: "/services/affiliate/affiliate-accuracy.php",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					if (data.Data != "")
					{
						$("#affiliateAccuracy").html("These reports are an estimate as of " + data.Data);
					}
				}
				else
				{
					$("#affiliateAccuracy").html("These reports are an estimation");
				}
				
				deferred.resolve();
			},
			error: function() 
			{
				$("#affiliateAccuracy").html("These reports are an estimation");
				deferred.resolve();
			}
		});
		
		return deferred.promise();
	}
	
	// ---------------------------------------------------------------------------------------------------------------------
	// misc
	
	/**
	 * Display list of coins
	 */
	coins()
	{
		var deferred = $.Deferred();
		
		$.ajax(
		{
			url: "/services/affiliate/coins.php",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					if (data.Data != "")
					{
						$("#coinSelector").html(data.Data);
						deferred.resolve();
					}
				}
				else
				{
					Misc.errorMessage("Error Reading Coins");
					deferred.reject();
				}
			},
			error: function() 
			{
				Misc.errorMessage("Error Reading Coins");
				deferred.reject();
			}
		});
		
		return deferred.promise();
	}

}
