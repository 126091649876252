
class Ticket
{
	constructor()
	{
		this.maxChars = $('#maxChars').val();
		this.events();
	}

	events()
	{
		let obj = this;

		$('#message').on('input', function() {
			const currentLength = $(this).val().length;
			$('#charCount').text(`${currentLength} / ${obj.maxChars}`);

			if (currentLength > obj.maxChars) {
				$('#charCount').addClass('text-danger');
			} else {
				$('#charCount').removeClass('text-danger');
			}
		});

		$('#createTicket').on('click', function() {
			let message = $('#message').val().trim();

			if (message === '') {
				Misc.message('Error', 'The message may not be empty', 'error');
				return;
			}

			if (message.length > obj.maxChars) {
				Misc.message('Error', 'The message is too long', 'error');
				return;
			}

			obj.create(message)
		});

		$(".req-auth").on("click", function()
		{
			obj.history();
		});
	}

	/**
	 * Retrieves the ticket history from the server and populates the table with the data.
	 * If there are no tickets, the history div will be hidden.
	 * If there is an error retrieving the history, an error message will be displayed.
	 *
	 * @return {void}
	 */
	history()
	{
		$("#reportLoading").show();

		$.ajax({
			url: 'tickets/history',
			type: 'GET',
			dataType: 'json',
			success: function(response)
			{
				if (response.Error.Code === 0)
				{
					let tickets = response.Data.data;

					// If there are tickets, populate the table
					if (tickets.length > 0) {

						let ticketRows = '';

						tickets.forEach(function(ticket) {
							ticketRows += `
						<tr>
							<td class="text-left">${ticket.created_at}</td>
							<td class="text-left">${ticket.reference}</td>
							<td class="text-left">${ticket.status.name}</td>
							<td class="text-left">${ticket.title}</td>
						</tr>`;
						});

						$('#userTickets tbody').html(ticketRows);
						$('#history').removeClass('d-none');
					}

				}
				else
				{
					Misc.message("Error", response.Error.Message + " (" + response.Error.Code + ")", "error");
				}

				$("#reportLoading").hide();
			},
			error: function()
			{
				Misc.message("Error", "Error Loading Support History (2)", "error");
				$("#reportLoading").hide();
			}
		});
	}

	/**
	 * Creates a new ticket with the specified message.
	 *
	 * @param {string} message - The message for the ticket.
	 * @return {void}
	 */
	create(message)
	{
		let obj = this;

		$("#reportLoading").show();

		$.ajax({
			url: 'tickets/create',
			type: 'POST',
			dataType: 'json',
			data: {
				message: message,
			},
			success: function(response) {
				let ticketReference = response.Data;

				//done!! text area to ""
				$('#message').val('');

				//done!! reset charCount
				$('#charCount').text(`0 / ${obj.maxChars}`);

				if (response.Error.Code === 0) {
					Swal.fire({
						title: 'Success!',
						type: 'success',
						html: 'Your ticket reference is: <br>' + ticketReference,
						confirmButtonText: 'OK'
					});

					obj.history();

				} else {
					Misc.message("Error", response.Error.Message + " (" + response.Error.Code + ")", "error");
				}
				$("#reportLoading").hide();
			},
			error: function() {
				Misc.message("Error", "Error Creating Ticket(3)", "error");
				$("#reportLoading").hide();
			}
		});
	}
}
