
class TradingUpdates
{

	// ---------------------------------------------------------------------------------------------------------------------
	// setup

	/**
	 * Constructor
	 */
	constructor(tradingPair, tradingSymbol, coinSymbol)
	{
		// instance vars
		this.tradingPair = tradingPair;
		this.tradingSymbol = tradingSymbol;
		this.coinSymbol = coinSymbol;

		var PageNumber = {
				BLANK: 0,
				BTC: 1,
				LTC: 2,
				NMC: 3,
				XRP: 4,
				ETH: 5,
				DASH: 6,
				ZEC: 7,
				BCC: 8,
				BTG: 9,
				BTCP: 10,
				USDT: 11,
				ADA: 12,
				USDTBTC: 13,
				NEO: 14,
				GAS: 15,
				XLM: 16,
				TRX: 17,
				BSV: 18,
				XMR: 19,
				DOGE: 20
		};

		this.tradeMarkets = [
			{ currency: "ZAR", symbol: "R" },
			{ currency: "USDT", symbol: "₮" }
		];

		this.NUMERIC_REGEXP = /[-]{0,1}[\d]*[\.]{0,1}[\d]+/g;
		this.COIN_DATA_INTERVAL = 2000;
		this.CHANGE_INTERVAL = 800;
		this.CHANGE_INTERVAL_SHORT = 500;
		this.TRADEHISTORY_PRICE = 0;
		this.TRADEHISTORY_COINAMOUNT = 1;
		this.TRADEHISTORY_TOTAL = 2;
		this.TRADEHISTORY_TIME = 3;
		this.TRADEHISTORY_TIMESTAMP = 4;

		this.BASE_PATH = "liveupdates.altcointrader.co.za";
		this.LIVE_STATS_PATH = this.BASE_PATH + "/liveStats";
		this.COIN_DETAILS_PATH = this.BASE_PATH + "/coinDetails";

		// tag ref
		this.$coinPrice = $("#currentCoinPrice");
		this.$coinHigh = $("#currentCoinHigh");
		this.$coinLow = $("#currentCoinLow");
		this.$coinVol = $("#currentCoinVolume");
		this.$coinBuy = $("#currentCoinBuy");
		this.$coinSell = $("#currentCoinSell");

		this.currentPrice = this.parseCoinPrice(this.$coinPrice.html());

		// jquery
		$.ajaxSetup({ cache: false });

		// window object
		window.coinPricesCallbacks = [];
		window.coinDetailsCallbacks = {};

		window.registerCoinPricesCallback = function(callback)
		{
		    if (coinPricesCallbacks.indexOf(callback) < 0)
		    {
		    	coinPricesCallbacks.push(callback);
		    }
		}

		window.registerCoinDetailsCallback = function(coin, callback)
		{
		    if (!Array.isArray(coinDetailsCallbacks[coin]))
		    {
		    	coinDetailsCallbacks[coin] = [];
		    }

		    if (coinDetailsCallbacks[coin].indexOf(callback) < 0)
		    {
		    	coinDetailsCallbacks[coin].push(callback);
		    }
		}

		window.currentCoinSymbol = coinSymbol.toLowerCase();

		// start calling
		this.registerCallbacks();
	}

	/**
	 * Setup sockets
	 */
	registerCallbacks()
	{
		var obj = this;

		// set market to watch
		var watchMarket = obj.coinSymbol.toLowerCase();
		if (obj.tradingPair.toLowerCase() == "usdt")
		{
			watchMarket = obj.tradingPair.toLowerCase() + "_" + obj.coinSymbol.toLowerCase()
		}

		// general stats
		this.liveStatsSocket = io.connect(this.LIVE_STATS_PATH);
		this.liveStatsSocket.on(watchMarket, function(data)
		{
			console.log("data received", watchMarket, data);

			obj.updateCoinIndicators(data);

			if (data.Price || data.Volume)
			{
				const tradeMarket = obj.getTradeMarketFromCoin(data.Currency);
				//const $row = $("#" + tradeMarket.currency + "_market_table tbody tr#" + data.Currency);
				const $row = $("#coin_market_table tbody tr#" + data.Currency);

				obj.updateCoinListIndicator(data.Currency, data, $row);
			}
		});

		this.liveStatsSocket.on("all", function(data)
		{
			console.log("data received", data);

			if (data.Currency === obj.coinSymbol) return;

			if (data.Price || data.Volume)
			{
				const tradeMarket = obj.getTradeMarketFromCoin(data.Currency);
				//const $row = $("#" + tradeMarket.currency + "_market_table tbody tr#" + data.Currency);
				const $row = $("#coin_market_table tbody tr#" + data.Currency);

				obj.updateCoinListIndicator(data.Currency, data, $row);
	        }
		});

		// coin specific stats
		this.coinDetailsSocket = io.connect(this.COIN_DETAILS_PATH);
		this.coinDetailsSocket.on(watchMarket, function(data)
		{
			obj.updateOrderTable(data.buyChanges, $("#buy-orders tbody"), obj.createBuyRow, obj.greaterThan);
			obj.updateOrderTable(data.sellChanges, $("#sell-orders tbody"), obj.createSellRow, obj.lessThan);
			obj.updateTradeHistoryTable(data.tradeHistoryChanges, $("#trade-history tbody"));
		});
	}

	// ---------------------------------------------------------------------------------------------------------------------
	// coin stats

	/**
	 * Update coin, high, low. volume, sell, buy
	 */
	updateCoinElements(coinData)
	{
		let decimalPlaces = this.getDecimalPlaces(this.coinSymbol);

		if (!!coinData.Price)
		{
			this.$coinPrice.html(this.tradingSymbol + " " + this.formatNumberThousandsSeparators(coinData.Price, decimalPlaces));
		}

		if (!!coinData.High)
		{
			this.$coinHigh.html("High <b>" + this.tradingSymbol + " " + this.formatNumberThousandsSeparators(coinData.High, decimalPlaces) + "</b>");
		}

		if (!!coinData.Low)
		{
			this.$coinLow.html("Low <b>" + this.tradingSymbol + " " + this.formatNumberThousandsSeparators(coinData.Low, decimalPlaces) + "</b>");
		}

		if (!!coinData.Volume)
		{
			this.$coinVol.html("Volume <b>" + this.formatNumberThousandsSeparators(coinData.Volume) + "</b>");
		}

		if (!!coinData.Buy)
		{
			this.$coinBuy.html("Buy <b>" + this.tradingSymbol + " " + this.formatNumberThousandsSeparators(coinData.Buy, decimalPlaces) + "</b>");
		}

		if (!!coinData.Sell)
		{
			this.$coinSell.html("Sell <b>" + this.tradingSymbol + " " + this.formatNumberThousandsSeparators(coinData.Sell, decimalPlaces) + "</b>");
		}
	}

	// ---------------------------------------------------------------------------------------------------------------------
	// coin list

	updateCoinPriceClass(newPrice)
	{
		let priceChangeClass;
		let iconClass;
		var obj = this;

		if (this.currentPrice < newPrice)
		{
			priceChangeClass = "price-up";
			iconClass = "fa-caret-up";
		}
		else if (this.currentPrice > newPrice)
		{
			priceChangeClass = "price-down";
			iconClass = "fa-caret-down";
		}

		if (!priceChangeClass) return;

		let $priceIcon = this.$coinPrice.find(".price-icon").first();

		this.$coinPrice.addClass(priceChangeClass);

		$priceIcon.removeClass("fa-caret-up");
		$priceIcon.removeClass("fa-caret-down");

		$priceIcon.addClass(iconClass);
		$priceIcon.removeClass("fa-blank");

		setTimeout(function()
		{
			obj.$coinPrice.removeClass(priceChangeClass);
			$priceIcon.addClass("fa-blank");
	    },
	    this.CHANGE_INTERVAL);
	}

	updateCoinIndicators(data)
	{
		this.updateCoinElements(data);

		if (!!data.Price)
		{
			let newPrice = this.parseCoinPrice(data.Price);
			this.updateCoinPriceClass(newPrice);
			this.currentPrice = newPrice;

			// change title
			var title = document.title;
			var count = title.match(/\|/g).length;
			let decimalPlaces = this.getDecimalPlaces(this.coinSymbol);

			if (count > 1)
			{
				var originalTitle = document.title.split(" | ");
				title = originalTitle[1] + " | " + originalTitle[2];
			}

			document.title = this.formatNumberThousandsSeparators(newPrice, decimalPlaces) + " | " + title;
			//console.log(this.formatNumberThousandsSeparators(newPrice, decimalPlaces) + " | " + title);
		}
	}

	getTradeMarketFromCoin(coin)
	{
		let currency = "ZAR";

	    if (coin.indexOf("_") > 0)
	    {
	    	currency = coin.substring(0, coin.indexOf("_"));
	    }

	    return this.tradeMarkets.find(function(tradeMarket)
	    {
	    	return tradeMarket.currency === currency;
	    });
	}

	// $row is useless, need to clean up
	updateCoinListIndicator(coin, coinData, $row)
	{
		// global
		let tradeMarket = this.getTradeMarketFromCoin(coin);
		let symbol = tradeMarket.symbol;
		let currency = tradeMarket.currency;

		//console.log("coin - " + coin);

		// clean non matching tickers
		if (coin.indexOf("_") > 0)
		{
			var coinSplit = coin.split("_");
			coin = coinSplit[1];
		}
		else if (coin == "BCC")
		{
			coin = "BCH";
		}

		//console.log("currency - " + currency);
		//console.log("coin - " + coin);

		// find matching row
		var matchedRow = $("#coin_market_table tbody tr[data-market='" + currency + "_" + coin + "']");
		var matchedRowMarket = matchedRow.data("market");

		//console.log("row market - " + matchedRowMarket);

		//let rowPair = $row.find(".market-price").data("pair");
		var rowPair = "";
		if (matchedRowMarket.indexOf("_") > 0)
		{
			var markets = matchedRowMarket.split("_");
			rowPair = markets[0];
		}

		//console.log("rowPair - " + rowPair);

		if (!!coinData.Price)
		{
			//let currentPrice = this.parseCoinPrice($row.find(".market-price").html().replace(symbol, "").replace(/\s/g, ""));
			let currentPrice = this.parseCoinPrice(matchedRow.find(".market-price").html().replace(symbol, "").replace(/\s/g, ""));
			let newPrice = this.parseCoinPrice(coinData["Price"]);

			if (currentPrice !== newPrice)
			{
				//console.log("updating - " + coin + " / " + currency);
				//console.log("replacing - " + $row.find(".market-price").html() + " with " + this.formatNumberThousandsSeparators(coinData["Price"], decimalPlaces));

				let decimalPlaces = this.getDecimalPlaces(coin);

				//$row.find(".market-price").html(symbol + " " + this.formatNumberThousandsSeparators(coinData["Price"], decimalPlaces));
				//$row.find(".market-price").html(this.formatNumberThousandsSeparators(coinData["Price"], decimalPlaces));
				matchedRow.find(".market-price").html(this.formatNumberThousandsSeparators(coinData["Price"], decimalPlaces));

				let priceChangeClass;

				if (currentPrice < newPrice)
				{
					priceChangeClass = "price-up";
				}
				else if (currentPrice > newPrice)
				{
					priceChangeClass = "price-down";
				}

				if (priceChangeClass)
				{
					//$row.find(".market-price").addClass(priceChangeClass);
					matchedRow.find(".market-price").addClass(priceChangeClass);

					setTimeout(function()
					{
						//$row.find(".market-price").removeClass(priceChangeClass);
						matchedRow.find(".market-price").removeClass(priceChangeClass);
					},
					this.CHANGE_INTERVAL);
				}
			}
		}

		if (!!coinData.Volume)
		{
			//let prevVolume = parseFloat($row.find(".market-volume").html().replace(/\s/g, ""));
			let prevVolume = parseFloat(matchedRow.find(".market-volume").html().replace(/\s/g, ""));
			let currentVolume = parseFloat(coinData["Volume"].replace(/\s/g, ""));

			if (prevVolume !== currentVolume)
			{
				//$row.find(".market-volume").html(this.formatNumberThousandsSeparators(coinData["Volume"]));
				matchedRow.find(".market-volume").html(this.formatNumberThousandsSeparators(coinData["Volume"]));
			}
		}
	}

	updateCoinListIndicators(err, data)
	{
		if (err) return;

		var obj = this;

		this.tradeMarkets.forEach(function(tradeMarket)
		{
			//const $rows = $("#" + tradeMarket.currency + "_market_table tbody");
			const $rows = $("#coin_market_table tbody");

			$rows.children().each(function(i, row)
			{
				let $row = $(row);
				let coin = $row.attr("id");
				let coinData = data[coin];

				if (!coinData) return;

				obj.updateCoinListIndicator(coin, coinData, $row);
			});
		});
	}

	updateCurrentCoinListIndicator(err, data)
	{
		const coin = data.coin.toUpperCase();
		const tradeMarket = this.getTradeMarketFromCoin(coin);

		const coinData = data.details;
		// const $row = $("#" + tradeMarket.currency + "_market_table tbody tr#" + this.coinSymbol);
		const $row = $("#coin_market_table tbody tr#" + this.coinSymbol);

		this.updateCoinListIndicator(this.coinSymbol, coinData, $row);
	}

	/**
	 * Create sell row
	 *
	 * @param sellTrade
	 */
	createSellRow(sellTrade)
	{
		return $("<tr>").addClass("orderUdSell")
				.append($("<td>").addClass("orderUdSPr").text(sellTrade[0]))
				.append($("<td>").addClass("orderUdSAm").text(sellTrade[1]))
				.append($("<td>").text(sellTrade[2]));
	}

	/**
	 * Create buy row
	 *
	 * @param sellTrade
	 */
	createBuyRow(sellTrade)
	{
		return $("<tr>").addClass("orderUdBuy")
				.append($("<td>").addClass("orderUdBPr").text(sellTrade[0]))
				.append($("<td>").addClass("orderUdBAm").text(sellTrade[1]))
				.append($("<td>").text(sellTrade[2]));
	}

	/**
	 * Create row for trade history
	 *
	 * @param sellTrade
	 */
	createTradeHistoryRow(tradeHistory)
	{
		return $("<tr>")
			.append($("<td>").text(tradeHistory[0]))
			.append($("<td>").text(tradeHistory[1]))
			.append($("<td>").text(tradeHistory[2]))
			.append($("<td>").text(tradeHistory[3]).attr("data-timestamp", tradeHistory[4]));
	}

	// ---------------------------------------------------------------------------------------------------------------------
	// trade rows

	addBuySellTradeRows(tradeData, $rows, createRowFn, compareFn)
	{
		var obj = this;

		$rows.children().each(function(i, row)
		{
			let $row = $(row);
			let $columns = $row.find("td");
			let price = $($columns[0]).text();
			let trade = tradeData[0];

			while (!!trade && compareFn(parseFloat(trade[0]), parseFloat(price)))
			{
				if (parseFloat(trade[0]) !== parseFloat(price))
				{
					let $newRow = createRowFn(trade);
					obj.animateAddRow($newRow.insertBefore($row));
				}

				tradeData.splice(0, 1);
				trade = tradeData[0];
			}
		});

		if (tradeData.length !== 0)
		{
			tradeData.forEach(function(trade)
			{
				let $row = $rows.children().last();
				let $newRow = createRowFn(trade);

				obj.animateAddRow($newRow.insertAfter($row));
			});
		}
	}

	removeBuySellTradeRows(tradeData, $rows)
	{
		var obj = this;

		tradeData.forEach(function(value)
		{
			let $removeRow = $rows.children("tr").filter(function()
			{
				let columns = $(this).children("td");
				let price = $(columns[0]).text();

				return price === value[0];
			});

			if (!!$removeRow.length)
			{
				obj.animateRemoveRow($removeRow);
			}
		});
	}

	changeBuySellTradeRows(tradeData, $rows)
	{
		var obj = this;

		tradeData.forEach(function(value)
		{
			let $changeRow = $rows.children("tr").filter(function()
			{
				let columns = $(this).children("td");
				let price = $(columns[0]).text();
				let amount = $(columns[1]).text();

				return price === value[0] && amount !== value[1];
			});

			if (!!$changeRow.length)
			{
				let $columns = $changeRow.find("td");

				$($columns[1]).text(value[1]);
				$($columns[2]).text(value[2]);

				$changeRow.addClass("change-trade-row");

				setTimeout(function()
				{
					$changeRow.removeClass("change-trade-row");
				},
				obj.CHANGE_INTERVAL_SHORT);
			}
		});
	}

	// ---------------------------------------------------------------------------------------------------------------------
	// order table

	updateOrderTable(changes, $rows, createRowFn, compareFn)
	{
		if (!changes) return;

		if (!!changes.add)
		{
			this.addBuySellTradeRows(changes.add.slice(), $rows, createRowFn, compareFn);
		}

		if (!!changes.remove)
		{
			this.removeBuySellTradeRows(changes.remove.slice(), $rows);
		}

		if (!!changes.change)
		{
			this.changeBuySellTradeRows(changes.change.slice(), $rows);
		}
	}

	// ---------------------------------------------------------------------------------------------------------------------
	// trade history

	/**
	 * Select change to apply to trade history
	 *
	 * @param changes
	 * @param $rows
	 */
	updateTradeHistoryTable(changes, $rows)
	{
		if (!changes) return;

		if (!!changes.add)
		{
			this.addTradeHistoryRow(changes.add.slice(), $rows);
		}

		if (!!changes.remove)
		{
			this.removeTradeHistoryRow(changes.remove.slice(), $rows);
		}

		if (!!changes.change)
		{
			this.changeTradeHistoryRows(changes.change.slice(), $rows);
		}
	}

	/**
	 * Add row to trade history
	 *
	 * @param tradeHistory
	 * @param $rows
	 */
	addTradeHistoryRow(tradeHistory, $rows)
	{
		var obj = this;

		$rows.children().each(function(i, row)
		{
			let $row = $(row);
			let $columns = $row.find("td");
			let price = $($columns[0]).text();
			let timestamp = parseFloat($($columns[3]).attr("data-timestamp"));
			let trade = tradeHistory[0];

			while (!!trade && trade[obj.TRADEHISTORY_TIMESTAMP] > timestamp)
			{
				let $newRow = obj.createTradeHistoryRow(trade);
				obj.animateAddRow($newRow.insertBefore($row));

				tradeHistory.splice(0, 1);
				trade = tradeHistory[0];
			}
		});

		if (tradeHistory.length !== 0)
		{
			tradeHistory.forEach(function(trade)
			{
				let $row = $rows.children().last();
				let $newRow = obj.createTradeHistoryRow(trade);

				obj.animateAddRow($newRow.insertAfter($row));
			});
		}
	}

	/**
	 * Edit trade history row
	 *
	 * @param tradeData
	 * @param $rows
	 */
	changeTradeHistoryRows(tradeData, $rows)
	{
		var obj = this;

		tradeData.forEach(function(trade)
		{
			let $changeRow = $rows.children("tr").filter(function()
			{
				let columns = $(this).children("td");
				let price = $(columns[0]).text();
				let timestamp = parseFloat($(columns[3]).attr("data-timestamp"));
				let amount = $(columns[1]).text();

				return trade[this.TRADEHISTORY_PRICE] === price &&
						trade[this.TRADEHISTORY_TIMESTAMP] === timestamp &&
						trade[this.TRADEHISTORY_COINAMOUNT] !== amount;
			});

			if (!!$changeRow.length)
			{
				let $columns = $changeRow.find("td");

				$($columns[1]).text(trade[this.TRADEHISTORY_COINAMOUNT]);
				$($columns[2]).text(trade[this.TRADEHISTORY_TOTAL]);

				$changeRow.addClass("change-trade-row");

				setTimeout(function()
				{
					$changeRow.removeClass("change-trade-row");
				},
				obj.CHANGE_INTERVAL_SHORT);
			}
		});
	}

	/**
	 * Delete trade history row
	 *
	 * @param tradeHistory
	 * @param $rows
	 */
	removeTradeHistoryRow(tradeHistory, $rows)
	{
		var obj = this;

		tradeHistory.forEach(function(trade)
		{
			let $removeRow = $rows.children("tr").filter(function()
			{
				let columns = $(this).children("td");
				let price = $(columns[0]).text();
				let timestamp = parseFloat($(columns[3]).attr("data-timestamp"));

				return trade[this.TRADEHISTORY_PRICE] === price && trade[this.TRADEHISTORY_TIMESTAMP] === timestamp;
			});

			if (!!$removeRow.length)
			{
				obj.animateRemoveRow($removeRow);
			}
		});
	}

	// ---------------------------------------------------------------------------------------------------------------------
	// misc

	/**
	 * Animate row addition
	 *
	 * @param $row
	 */
	animateAddRow($row)
	{
		const rowClass = "change-trade-row";

		return $row.addClass(rowClass)
				.find("td")
				.wrapInner('<div style="display: none;" />')
				.parent()
				.find("td > div")
				.slideDown(500, function()
				{
					var $set = $(this);
					$set.replaceWith($set.contents());
					$row.removeClass(rowClass);
				});
	}

	/**
	 * Animate row removal
	 *
	 * @param $row
	 */
	animateRemoveRow($row)
	{
		const rowClass = "change-trade-row";

		return $row.addClass(rowClass)
				.find("td")
				.wrapInner('<div style="display: block;" />')
				.parent()
				.find("td > div")
				.slideUp(500, function()
				{
					$(this).parent().parent().remove();
				});
	}

	/**
	 * Parse prices
	 */
	parseCoinPrice(value)
	{
		return parseFloat(value.replace(/\s+/g, '').replace(new RegExp("&nbsp;", "g"), "").match(this.NUMERIC_REGEXP)[0]);
	}

	/**
	 * Return number of decimal places to display
	 *
	 * @param coinSymbol
	 */
	getDecimalPlaces(coinSymbol)
	{
		coinSymbol = coinSymbol.toUpperCase();

		if (coinSymbol === "SHIB" || coinSymbol === "BTT" || coinSymbol === "PEPE")
		{
			return 8;
		}

		if (coinSymbol === "TRX" || coinSymbol === "DOGE" || coinSymbol === "SGB" || coinSymbol === "FLR")
		{
			return 4;
		}

		return 2;
	}

	/**
	 * Format currency
	 *
	 * @param value
	 * @param decimalPlaces
	 */
	formatNumberThousandsSeparators(value, decimalPlaces)
	{
		if (typeof value === "string")
		{
			//value = parseFloat(value.replace(/\s+/g, ''));
			value = parseFloat(value.replace(/\s+/g, '').replace(new RegExp("&nbsp;", "g"), ""));
		}

		decimalPlaces = decimalPlaces || 2;

		return this.thousandSeparator(value, decimalPlaces);
	}

	/**
	 * Calculate decimal places
	 *
	 * @param value
	 * @param decimalPlaces
	 */
	thousandSeparator(value, decimalPlaces)
	{
		function reverse(text)
		{
			return text.split("").reverse().join("");
		}

		var rx = /(\d{3}(?!.*\.|$))/g;
		var separator = " ";

		decimalPlaces = decimalPlaces || 2;

		return reverse(reverse(value.toFixed(decimalPlaces)).replace(rx, "$1" + separator));
	}

	greaterThan(a, b)
	{
		return a >= b;
	}

	lessThan(a, b)
	{
		return a <= b;
	}

	/*
	Array.prototype.find = Array.prototype.find || function(callback)
	{
		for (let i = 0; i < this.length; i++)
		{
			if (callback(this[i], i))
			{
				return this[i];
			}
		}
	}

	Array.prototype.findIndex = Array.prototype.findIndex || function(callback)
	{
		if (this === null)
		{
			throw new TypeError('Array.prototype.findIndex called on null or undefined');
		}
		else if (typeof callback !== 'function')
		{
			throw new TypeError('callback must be a function');
		}

		let list = Object(this);
		let length = list.length >>> 0;
		let thisArg = arguments[1];

		for (var i = 0; i < length; i++)
		{
			if (callback.call(thisArg, list[i], i, list))
			{
				return i;
			}
		}

		return -1;
	}
	*/

}
