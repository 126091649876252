
/**
 * Global page functions
 */
$(document).ready(function()
{
	$("#dismissAlert").click(function ()
	{
		Misc.dismissAlert();
	});

	Misc.themeControl();
});

/**
 * Setup for login process
 */
function loginSetup()
{
	var login = new Login();
}

/**
 * Setup for trading page
 */
function tradingSetup(reload = false)
{
	// return early if no content
	if (($("#jsTradingSymbol").length == 0) || ($("#jsTradingSymbol").val() == "") || ($("#jsCoinSymbol").val() == "")) return;

	// force reload
	if (reload) location.reload();

	var trading = new Trading($("#jsTradingPair").val(), $("#jsTradingSymbol").val(), $("#jsCoinSymbol").val());
}

function easySetup()
{
	var easy = new Easy();

	$.when
	(
		easy.coins()
	)
	.done(function()
	{
		easy.events();
	});
}

function cardSetup()
{
	var card = new Card();

	$.when
	(
		card.coins()
	)
	.done(function()
	{
		card.events();
	});
}

/**
 * Setup for market page
 */
function marketSetup()
{
	// return early if no content
	if (($("#jsTradingSymbol").length == 0) || ($("#jsTradingSymbol").val() == "") || ($("#jsCoinSymbol").val() == "")) return;

	var market = new Market($("#jsTradingPair").val(), $("#jsTradingSymbol").val(), $("#jsCoinSymbol").val());
}

/**
 * Setup for withdrawals history
 */
function withdrawalsHistorySetup()
{
	var account = new Account();
	account.withdrawalsHistoryEvents();
}

/**
 * Setup for withdrawals
 */
function withdrawalsSetup()
{
	var account = new Account();
	account.withdrawalsEvents();
}

/**
 * Setup for trade history
 */
function tradeHistorySetup()
{
	var account = new Account();
	account.tradeHistoryEvents();
}

function depositHistorySetup()
{
	var deposit = new Deposit();
	deposit.depositHistoryEvents();
}

/**
 * Setup for deposits
 */
function depositsSetup(ref, id)
{
	var deposit = new Deposit();
	deposit.events(ref, id);
}

/**
 * Setup for profile
 */
function profileSetup(physicalCountry, postalCountry)
{
	var profile = new Profile();
	profile.events(physicalCountry, postalCountry);
}

/**
 * Setup for verify
 */
function verifySetup()
{
	return new Verify();
}

/**
 * Setup for wallet
 */
function walletSetup(id)
{
	var wallet = new Wallet();
	wallet.display(id);
}

/**
 * Setup for wallet manger
 */
function walletManagerSetup()
{
	var wallet = new Wallet();
	wallet.manager();
}

/**
 * Setup for affiliate index page
 */
function mainSetup(code)
{
	var affiliate = new Affiliate();
	affiliate.mainEvents();
	affiliate.displayCommissions();
	affiliate.displayDetails(code);
}

/**
 * Setup for savings page
 */
function savingsSetup()
{
	var savings = new Savings();
	savings.events();
}

/**
 * Setup for loans page
 */
function loansSetup()
{
	var loans = new Loans();
	loans.events();
}

/**
 * Setup for earnings page
 */
function earningsSetup()
{
	var affiliate = new Affiliate();

	$.when
	(
		affiliate.coins(),
		affiliate.affiliateAccuracy(),
		affiliate.earningsTiers()
	)
	.done(function()
	{
		affiliate.earningsEvents();
	});
}

/**
 * Setup for claims page
 */
function claimsSetup(ticker)
{
	var affiliate = new Affiliate();

	$.when
	(
		affiliate.coins(),
		affiliate.affiliateAccuracy()
	)
	.done(function()
	{
		affiliate.claimRequirements(ticker);
		affiliate.currentEarnings(ticker);
		affiliate.claims(ticker);
		affiliate.claimsEvents();
	});
}

/**
 * Setup for my affiliates page
 */
function myAffiliatesSetup()
{
	var affiliate = new Affiliate();
	affiliate.affiliateEvents();
	affiliate.affiliates("", 1, null);
	affiliate.affiliateAccuracy();
}
