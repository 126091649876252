
class Table
{

	constructor(container, headers, data, id = "")
	{
		var table = "<table class='reportsTable'";
		
		if (id != "")
		{
			table += " id='" + id + "'";
		}
		
		table += "'>";
		table += "<thead><tr>";
		
		$.each(headers, function(i, item)
		{
			table += "<th>" + item + "</th>";
		});
		
		table += "</tr></thead><tbody>";
		
		$.each(data, function(i, item)
		{
			table += "<tr>";
			
			$.each(item, function(i, col)
			{
				table += "<td>" + col + "</td>";
			});
			
			table += "</tr>";
		});
		
		table += "</tbody></table>";
		
		$(container).html(table);
	}
	
}
