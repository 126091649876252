
class Wallet
{

	display(address)
	{
		// init qr code
		$("#qr").qrcode({text: $("#address").val(), 
						width: 200, 
						height: 200});
		
		// show modal
		if (address != "")
		{
			this.showReceive();
		}
		
		// setup listeners
		this.events();
		this.sockets();
	}

	events()
	{
		var obj = this;
		
		$('#fiatprice, #bitcoinprice').on("focus", function()
		{
			if ($(this).val() == 0)
			{
				$(this).val("");
			}
		});
		
		$('#fiatprice').on("blur", function()
		{
			if (($(this).val() == "") || ($(this).val() == 0))
			{
				$(this).val("0.00");
			}
		});
		
		$('#bitcoinprice').on("blur", function()
		{
			if (($(this).val() == "") || ($(this).val() == 0))
			{
				$(this).val("0.00000000");
			}
		});
		
		// fiat price change
		$(document).on("change paste keyup", "#fiatprice", function() 
		{
			var currencyrate = obj.cleanNumber($("#currencyrate").val());
			var fiat_input = obj.cleanNumber($("#fiatprice").val());
			var coinsDue = (fiat_input / currencyrate).toFixed(8);
			
			$("#currencyrate").val(currencyrate);
			$("#fiatprice").val(fiat_input);
			$("#bitcoinprice").val(coinsDue * 1);
			
			$("#qr").empty();
			$("#qr").qrcode({text: "bitcoin:" + $("#address").val() + "?amount=" + coinsDue,
							width: 200,
							height: 200});
		});

		// crypto price change
		$(document).on("change paste keyup", "#bitcoinprice", function() 
		{
			var currencyrate = obj.cleanNumber($("#currencyrate").val());
			var alt_input = obj.cleanNumber($("#bitcoinprice").val());
			var altssDue = (alt_input * currencyrate).toFixed(2);
			
			$("#currencyrate").val(currencyrate);
			$("#bitcoinprice").val(alt_input);
			$("#fiatprice").val(altssDue * 1);
			
			$("#qr").empty();
			$("#qr").qrcode({text: "bitcoin:" + $("#address").val() + "?amount=" + alt_input,
							width: 200,
							height: 200});
		});
		
		// user triggered events
		$("#showReceive").click(function()
		{
			obj.showReceive();
		});
		
		$("#showSend").click(function()
		{
			obj.showSend();
		});
		
		$(".close").click(function()
		{
			// change url
			window.history.pushState({}, "", "/wallet/");
			
			// close modal
			obj.closeModal();
		});
	}
	
	showReceive()
	{
		// change url
		window.history.pushState({}, "", $("#url").val());
		
		// show modal
		$('#modalWalletReceive').modal("show");
		
		$('#modalWalletReceive').on('shown.bs.modal', function(e) 
		{
			$("#waitingPayment").show();
		})
	}
	
	showSend()
	{
		// show modal
		$('#modalWalletSend').modal("show");
		
		// capture
		let scanner = new Instascan.Scanner({ video: document.getElementById('preview') });

		scanner.addListener('scan', function (content) 
		{
			// show QR code content
			if (content.indexOf("?"))
			{
				var params = content.split("?");
				var amount = params[1].split("=")[1];
				var address = params[0].replace("bitcoin:", "");
			
				$("#sendAmount").html(amount);
				$("#sendAddress").html(address);
				$("#sendNow").removeClass("disabled");
			}
		});

		Instascan.Camera.getCameras().then(function (cameras) 
		{
	        if (cameras.length > 0) 
	        {
	        	scanner.start(cameras[0]);
	        } 
	        else 
	        {
	        	console.error('No cameras found.');
	        }

		}).catch(function (e) 
		{
			console.error(e);
		});
	}

	closeModal()
	{
		$('.modal').modal("hide");
	}
	
	sockets()
	{
		var btcs = new WebSocket("wss://ws.blockchain.info/inv");
		var coinaddress = $("#address").val();	
		
		btcs.onopen = function()
		{
			btcs.send(JSON.stringify({"op":"addr_sub", "addr":coinaddress}));
		};
	
		btcs.onmessage = function(onmsg)
		{
			var response = JSON.parse(onmsg.data);

			console.log(response);
	
			var getouts = response.x.out;
			var countOuts = getouts.length;
	
			for(var i = 0; i < countOuts; i++)
			{
				// out address = receive address
				if (response.x.out[i].addr == coinaddress)
				{
					// convert the amount from satoshies to btc
					var btcamount = response.x.out[i].value / 100000000;
					
					// show success
					//var success = "<div id='walletSuccess' class=\"text-success mb-2\">" + btcamount + " BTC Transaction Created</div>";
					//success += "<div id='walletMessage'>Please <a href='https://www.blockchain.com/btc/address/" + coinaddress + "?filter=2' class='pagelink' target='_blank'>check the blockchain</a> for confirmations.</div>";
					
					var success = "<div id='walletSuccess' class=\"text-success mb-2\">" + btcamount + " BTC Success</div>";
					$("#websocketSuccess").html(success);
					$("#websocket").hide();
					
					// play audio
					var aud = document.getElementById("myAudio"); 
					aud.play();
				}
			}
	
		};
	}
	
	/**
	 * Manage wallet addresses
	 */
	manager()
	{
		var obj = this;
		
		// show listing
		obj.showAddresses();
		
		// listen for events
		$(document).on("click", "#showAdd", function() 
		{
			obj.showAddAddress();
		});
		
		$(document).on("click", "#addAddress", function() 
		{
			if ($("#addAddressForm")[0].checkValidity())
			{
				obj.addAddress();
			}
		});
		
		$(document).on("click", ".showEdit", function() 
		{
			obj.showEditAddress($(this).data("id"));
		});
		
		$(document).on("click", "#editAddress", function() 
		{
			if ($("#editAddressForm")[0].checkValidity())
			{
				obj.editAddress();
			}
		});
		
		$(document).on("click", ".close", function() 
		{
			obj.closeModal();
		});
	}
	
	showAddresses()
	{
		$("#reportLoading").show();
		
		$.ajax(
		{
			url: "/services/wallet/list-addresses.php",
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					$("#addressListing table tbody").html(data.Rows);
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error Listing Addresses");
				
				$("#addressListing table tbody").html("<tr><td colspan='4'>No Addresses</td></tr>");
				
				$("#reportLoading").hide();
			}
		 });
	}
	
	showAddAddress()
	{
		$.ajax(
		{
			url: "/services/wallet/show-add-address.php",
			data: 
			{
				"ticker": $("#ticker").val()
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					$("#modalContainer").html(data.Modal);
					$("#modalWalletAdd").modal("show");
					
					$("#addAddress").val(data.Address);
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
			},
			error: function() 
			{
				Misc.errorMessage("Error Generating Address");
			}
		 });
	}
	
	addAddress()
	{
		var obj = this;
		
		$.ajax(
		{
			url: "/services/wallet/add-address.php",
			data: 
			{
				"label": $("#addName").val(),
				"ticker": $("#ticker").val(),
				"default": ($("#addDefault").prop("checked")) ? 1 : 0
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					obj.showAddresses();
					obj.closeModal();
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
			},
			error: function() 
			{
				Misc.errorMessage("Error Adding Address");
			}
		 });
	}
	
	showEditAddress(id)
	{
		$.ajax(
		{
			url: "/services/wallet/show-edit-address.php",
			data: 
			{
				"id": id
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					// modal
					$("#modalContainer").html(data.Modal);
					$("#modalWalletEdit").modal("show");

					// set
					$("#addressID").val(id);
					$("#editName").val(data.Label);
					$("#editAddress").val(data.Address);
					$("#editDefault").prop("checked", (data.Default == 1));
					
					if ($("#editDefault").prop("checked"))
					{
						$("#editDefault").attr("disabled", "disabled");
					}
					else
					{
						$("#editDefault").removeAttr("disabled");
					}
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
			},
			error: function() 
			{
				Misc.errorMessage("Error Reading Address Details");
			}
		 });
	}
	
	editAddress()
	{
		var obj = this;
		
		$.ajax(
		{
			url: "/services/wallet/edit-address.php",
			data: 
			{
				"id": $("#addressID").val(),
				"label": $("#editName").val(),
				"default": ($("#editDefault").prop("checked")) ? 1 : 0
			},
			type: "POST",
			success: function(data) 
			{
				if (data.ErrorCode == 0)
				{
					obj.showAddresses();
					obj.closeModal();
					//location.reload(); 
				}
				else
				{
					Misc.errorMessage(data.Error + " (" + data.ErrorCode + ")");
				}
			},
			error: function() 
			{
				Misc.errorMessage("Error Editing Address");
			}
		 });
	}
	
	/**
	 * Remove unwanted characters from numeric input
	 */
	cleanNumber(value)
	{
		// max length
		var max = (value.indexOf(".") > -1) ? 21 : 12;
		var cleaned = (value.length >= max) ? value.substr(0, max) : value;
		
		// replace non numeric or period chars
		cleaned = cleaned.replace(/[^0-9\.]+/g, "");
		
		// format number
		if (cleaned.indexOf(".") > -1)
		{
			var parts = cleaned.split(".");
			cleaned = parts[0].match(/[0-9]{1,12}/) + "." + parts[1].match(/[0-9]{0,8}/);
		}
		
		// strip 0's in front
		cleaned = cleaned.replace(/^0+(?!\.|$)/, "");
		
		// replace empty string with 0
		cleaned = (cleaned != "") ? cleaned : 0;
		
		return cleaned;
	}

}
