
class TradingViewCharts
{
	
	/**
	 * Constructor
	 */
	constructor(tradingPair, coinSymbol)
	{
		var backgroundColor = Misc.rgb2hex($("body").css("background-color"));
		var theme = ($("body").data("theme") != "dark") ? "Light" : "Dark";

		// live or dev
		this.datafeedUrl = "https://www.altcointrader.co.za/api/v3/chart";
		/*
		if (!window.location.href.includes("altcointrader"))
		{
			this.datafeedUrl = "/api/v3/chart";
		}
		*/
		
		this.updateInterval = 1000 * 60;
		this.widget = window.tvWidget = null;
		this.symbol = (tradingPair.toUpperCase() != "ZAR") ? tradingPair.toUpperCase() + coinSymbol.toUpperCase() : coinSymbol.toUpperCase();
		this.options = {
			//debug: true, // uncomment this line to see Library errors
			fullscreen: false,
			autosize: true,
			width: "100%",
			height: "100%",
			symbol: this.symbol,
			theme: theme,
			toolbar_bg: backgroundColor,
			loading_screen: { backgroundColor: backgroundColor },
			//custom_css_url: "/includes/client/min/tradingview.dark.css",
			interval: "30",
			timezone: "exchange",
			container_id: "tv_chart_container",
			// BEWARE: no trailing slash is expected in feed URL
			datafeed: new Datafeeds.UDFCompatibleDatafeed(this.datafeedUrl, this.updateInterval),
			//library_path: "charts/trading-view/charting_library/",
			library_path: "/includes/client/external/trading-view/charting_library/",
			locale: this.getParameterByName('lang') || "en",
			// Regression Trend-related functionality is not implemented yet, so
			// it's hidden for a while
			drawings_access: {
				type: 'black',
				tools: [
				{ name: "Regression Trend" }
				]
			},
			time_frames: [
				{ text: "1d", resolution: "5", description: "1 day" },
				{ text: "5d", resolution: "15", description: "5 day" },
				{ text: "1m", resolution: "30", description: "1 month" },
				{ text: "3m", resolution: "60", description: "3 month" },
				{ text: "1y", resolution: "1D", description: "1 year" }
			],
			//enabled_features: ["study_templates"],
			hide_side_toolbar: false,
			// disabled_features: ["use_localstorage_for_settings"],
			// charts_storage_url: 'http://saveload.tradingview.com',
			// charts_storage_api_version: "1.1",
			// client_id: 'altcointrader.co.za',
			// user_id: 'public_user_id'
		};
		
		this.widget = window.tvWidget = new TradingView.widget(this.options);
	}
	
	/**
	 * Destructor
	 */
	kill()
	{
		//this.widget.remove();
		this.widget = window.tvWidget = null;
	}
	
	/**
	 * Return param name
	 */
	getParameterByName(name) 
	{
		var name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
		var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), 
			results = regex.exec(location.search);
	    
		return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
	}
	
}
