
class BarChart
{

	/**
	 * Setup
	 */
	constructor(data, container)
	{
		// default
		$(container).html("<div>No Data</div>");
		
		// validate
		if ((data == "") || (data.length == 0) || 
			((data[0]["Data"].length == 0) &&
			(data[1]["Data"].length == 0) &&
			(data[2]["Data"].length == 0) &&
			(data[3]["Data"].length == 0) &&
			(data[4]["Data"].length == 0))
			)
		{
			return;
		}
		
		// setup
		$(container).html("");
		var obj = this;
		var width = $(container).width();
		var height = $(container).height();
		
		this.svg = d3.select(container).append("svg")
					.attr("width", width)
					.attr("height", height);
		this.margin = {top: 20, right: 30, bottom: 30, left: 50};
		this.width = width - this.margin.left - this.margin.right;
		this.height = height - this.margin.top - this.margin.bottom;
		this.parseTime = d3.timeParse("%Y-%m-%d");
		
		this.minX = 0;
		this.maxX = 0;
		this.minY = new Date();
		this.maxY = 0;
		
		data.forEach(function(d)
		{
			d.Data.forEach(function(e)
			{
				obj.minX = (parseFloat(e.Amount) < parseFloat(obj.minX)) ? e.Amount : obj.minX;
				obj.maxX = (parseFloat(e.Amount) > parseFloat(obj.maxX)) ? e.Amount : obj.maxX;
				
				var dateTime = obj.parseTime(e.Date);
				
				obj.minY = (dateTime < obj.minY) ? dateTime : obj.minY;
				obj.maxY = (dateTime > obj.maxY) ? dateTime : obj.maxY;
			});
		});
		
		this.xScale = d3.scaleTime()
				.domain([Misc.padDate(obj.minY, -1), Misc.padDate(obj.maxY, 1)])
				.range([0, this.width])
				.nice();
		this.yScale = d3.scaleLinear()
				.domain([obj.minX, obj.maxX])
				.range([this.height, 0])
				.nice();
		this.zScale = d3.scaleOrdinal(d3.schemeCategory10);
		
		this.axisGroup = this.svg.append("g")
								.attr("transform", "translate(" + (this.margin.left + 2) + "," + this.margin.top + ")");
		
		// display
		this.axis(data);
		
		data.forEach(function(d)
		{
			obj.bars(d.Data, d.Tier);
			
			// hack tricks D3 to matching colors on bar & line chart
			obj.zScale(d.Tier);
		});
	}
	
	/**
	 * Display axis'
	 */
	axis(data)
	{
		var obj = this;
		
		this.axisGroup.append("g")
					.attr("transform", "translate(0," + (this.height) + ")")
					.style("font-size","10px")
					.call(d3.axisBottom(obj.xScale));
		
		this.axisGroup.append("g")
					.style("font-size","10px")
					.call(d3.axisLeft(obj.yScale).ticks(3));
	}
	
	/**
	 * Display bars
	 */
	bars(data, tier)
	{
		var obj = this;
		
		var barGroup = this.svg.append("g")
								.attr("transform", "translate(" + (this.margin.left + 2) + "," + this.margin.top + ")");

		barGroup.selectAll("rect")
					.data(data)
					.enter()
					.append("rect")
						.attr("class", "tierOn bar bar" + tier)
						.attr("fill", function(d) { return obj.zScale(tier); })
						.attr("x", function(d, i) { return obj.xScale(new Date(d.Date)); })
						.attr("y", function(d) { return obj.height; })
						.attr("width", function(d) { return 10; })
						.attr("height", function(d) { return 0; });
	
		barGroup.selectAll("rect")
				.transition()
				.duration(2000)
					.attr("y", function(d) { return obj.yScale(d.Amount); })
					.attr("height", function(d) { return obj.height - obj.yScale(d.Amount); });
	}

}