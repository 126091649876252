
const BUY_ORDERBOOK = 1;
const SELL_ORDERBOOK = 2;

class Easy
{
	
	constructor()
	{
		var buyOrderBook = "";
		var sellOrderBook = "";
	}
	
	events()
	{
		var obj = this;
		
		// navigation
		$("#buyTab").click(function()
		{
			$("#buyTab").addClass("highlight");
			$("#sellTab").removeClass("highlight");
			
			$("#buyView").show();
			$("#sellView").hide();
		});
		
		$("#sellTab").click(function()
		{
			$("#buyTab").removeClass("highlight");
			$("#sellTab").addClass("highlight");
			
			$("#buyView").hide();
			$("#sellView").show();
		});
		
		// input
		$("#amount100").click(function()
		{
			$("#buyPrice").val(100).keyup();
			$("#otherAmount").hide();
			
			obj.buttonSelected(this);
			
			// record event
			ga('send', 'event', 'MarketOrder', 'Preset100');
		});
		
		$("#amount1000").click(function()
		{
			$("#buyPrice").val(1000).keyup();
			$("#otherAmount").hide();
			
			obj.buttonSelected(this);
			
			// record event
			ga('send', 'event', 'MarketOrder', 'Preset1000');
		});
		
		$("#amountOther").click(function()
		{
			$("#otherAmount").show();
			$("#buyPrice").focus();
			
			obj.buttonSelected(this);
			
			// record event
			ga('send', 'event', 'MarketOrder', 'PresetOther');
		});
		
		$("#maxBuy").click(function()
		{
			$("#buyPrice").val($("#buyPairAvailable").val()).keyup();
			
			// record event
			ga('send', 'event', 'MarketOrder', 'MaxBuy');
		});
		
		$("#maxSell").click(function()
		{
			$("#sellPrice").val($("#sellTickerBalance").val()).keyup();
			
			// record event
			ga('send', 'event', 'MarketOrder', 'MaxSell');
		});
		
		// change coin
		$(".dropdown-menu li").click(function()
		{
			var section = "";
			
			if (!$("#buyTab").hasClass("highlight"))
			{
				section = "&sell";
			}
			
			window.location.href = "/easy-buy-sell?market=zar-" + $(this).data("coin").toLowerCase() + section;
		});
		
		$(document).on('propertychange change paste keyup input', '#buyPrice', function() 
		{
			var price = obj.cleanNumber($("#buyPrice").val());
			var pair = $("#buyPair").val();
			var ticker = $("#buyTicker").val();
			
			// validation
			$("#buyPrice").val(price);
			
			if ((obj.cleanNumber(price) <= 0) || (pair == "") || (ticker == ""))
			{
				return;
			}
			
			// load sell order book or estimate
			if (obj.sellOrderBook == undefined)
			{
				obj.loadOrderBook(SELL_ORDERBOOK, pair, ticker);
			}
			else
			{
				obj.estimateBuy(price);
			}
		});
		
		$(document).on('propertychange change paste keyup input', '#sellPrice', function() 
		{
			var amount = obj.cleanNumber($("#sellPrice").val());
			var pair = $("#sellPair").val();
			var ticker = $("#sellTicker").val();
			
			// validation
			$("#sellPrice").val(amount);
			
			if ((amount <= 0) || (pair == "") || (ticker == ""))
			{
				return;
			}
			
			// load buy order book or estimate
			if (obj.buyOrderBook == undefined)
			{
				obj.loadOrderBook(BUY_ORDERBOOK, pair, ticker);
			}
			else
			{
				obj.estimateSell(amount);
			}
		});
		
		// trade events
		$("#easyBuy").click(function()
		{
			var maxDiff = parseFloat($("#maxEstimateDifference").val());
			var originalEstimate = parseFloat($("#buyEstimate").val());
			var pair = $("#buyPair").val();
			var ticker = $("#buyTicker").val();
			
			// recalculate estimate
			$.when
			(
				// reload orderbook
				obj.loadOrderBook(SELL_ORDERBOOK, pair, ticker)
			)
			.done(function()
			{
				// check estimate diff
				var newEstimate = parseFloat($("#buyEstimate").val());
				
				// price diff more than previous
				if (newEstimate > originalEstimate)
				{
					// price % more than threshold
					if (Misc.relDiff(originalEstimate, newEstimate) >= maxDiff)
					{
						swal.fire({
							type: 'warning',
							title: "Estimate Update",
							html: "Significant price change, are you sure you want to continue?",
							showCancelButton: true,
							focusConfirm: true,
							cancelButtonText: 'OK'
						});
					}
					else
					{
						obj.confirmBuy();
					}
				}
				else
				{
					obj.confirmBuy();
				}
			});
		});
		
		$("#easySell").click(function()
		{
			var maxDiff = parseFloat($("#maxEstimateDifference").val());
			var originalEstimate = parseFloat($("#sellEstimate").val());
			var pair = $("#sellPair").val();
			var ticker = $("#sellTicker").val();
			
			// recalculate estimate
			$.when
			(
				// reload orderbook
				obj.loadOrderBook(BUY_ORDERBOOK, pair, ticker)
			)
			.done(function()
			{
				// check estimate diff
				var newEstimate = parseFloat($("#sellEstimate").val());

				// price diff more than previous
				if (newEstimate > originalEstimate)
				{
					// price % more than threshold
					if (Misc.relDiff(originalEstimate, newEstimate) >= maxDiff)
					{
						swal.fire({
							type: 'warning',
							title: "Estimate Update",
							html: "Significant price change, are you sure you want to continue?",
							showCancelButton: true,
							focusConfirm: true,
							cancelButtonText: 'OK'
						});
					}
					else
					{
						obj.confirmSell();
					}
				}
				else
				{
					obj.confirmSell();
				}
			});
		});
		
		// misc events
		$("#easyInfo").click(function()
		{
			swal.fire({
				title: "Easy Buy &amp; Sell", 
				html: "The amounts displayed are estimates based on current prices. <a href='https://altcointrader.zendesk.com/hc/en-gb/articles/360017362899' target='_blank'>Learn more</a> in our help centre.", 
				type: "info",
				confirmButtonColor: "#6c757d"
			});
		});
		
		$(".goExchange").click(function()
		{
			window.location.href = "/";
		});
		
		$(".goBuyMore").click(function()
		{
			window.location.href = "/easy-buy-sell?market=zar-" + $("#buyTicker").val();
		});
		
		$(".goSellMore").click(function()
		{
			window.location.href = "/easy-buy-sell?market=zar-" + $("#sellTicker").val() + "&sell";
		});
		
		$(".goBalance").click(function()
		{
			window.location.href = "/balances";
		});
	}
	
	/**
	 * Load order book
	 */
	loadOrderBook(_type, _pair, _ticker)
	{
		var obj = this;
		var deferred = $.Deferred();
		
		$("#reportLoading").show();
		
		$.ajax(
		{
			url: "/services/easy/order-book.php",
			data: 
			{
				"type": _type,
				"pair": _pair,
				"ticker": _ticker
			},
			type: "POST",
			success: function(data) 
			{
				if (data.Error.Code == 0)
				{
					// save order book & calculate estimate
					if (_type == SELL_ORDERBOOK)
					{
						obj.sellOrderBook = data.Data.OrderBook;
						obj.estimateBuy();
					}
					else
					{
						obj.buyOrderBook = data.Data.OrderBook;
						obj.estimateSell();
					}
					
					deferred.resolve();
				}
				else
				{
					Misc.errorMessage(data.Error.Message + " (" + data.Error.Code + ")");
					deferred.reject();
				}
				
				$("#reportLoading").hide();
			},
			error: function() 
			{
				Misc.errorMessage("Error loading order book");
				deferred.reject();
				
				$("#reportLoading").hide();
			}
		});
		
		return deferred.promise();
	}
	
	/**
	 * Confirmations
	 */
	confirmBuy()
	{
		var obj = this;
		var buyMax = parseFloat($("#buyMax").val());
		var minAmount = parseFloat($("#minAmount").val());
		var message = $("#buyConfirm").html();
		var price = parseFloat(obj.cleanNumber($('#buyPrice').val()));
		var buy = parseFloat(obj.cleanNumber($('#buyEstimate').val()));
		var pair = $("#buyPair").val().toUpperCase();
		var ticker = $("#buyTicker").val().toUpperCase();
		var remaining = $("#buyRemaining").val();
		
		// verify
		if (buy > buyMax)
		{
			swal.fire({
				title: "Easy Buy &amp; Sell", 
				html: "You can not buy more than " + buyMax + " " + ticker + " on Easy Buy & Sell. Please use main exchange for large orders.<br /><a href='/'>Go to Exchange</a>", 
				type: "info",
				confirmButtonColor: "#6c757d"
			});
			
			return;
		}
		
		if (price < minAmount)
		{
			swal.fire({
				title: "Easy Buy &amp; Sell", 
				html: "You can not buy less than " + minAmount + " " + pair + " on Easy Buy & Sell. Please use main exchange for large orders.<br /><a href='/'>Go to Exchange</a>", 
				type: "info",
				confirmButtonColor: "#6c757d"
			});
			
			return;
		}
		
		if (remaining > 0)
		{
			$("#buyEstimate").val(0);
			$("#buyEstimateView span ").html(0);
			
			$("#buyAmountCoins").val(0);
			$("#buyCoinPrice").val(0);
			
			$("#buyPrice").val(0);
			
			swal.fire({
				title: "Easy Buy &amp; Sell", 
				html: "Insufficient liquidity to full order. Please use main exchange for large orders.<br /><a href='/'>Go to Exchange</a>", 
				type: "info",
				confirmButtonColor: "#6c757d"
			});
			
			return;
		}
		
		$("#buyForm").submit();
	}
	
	confirmSell()
	{
		var obj = this;
		var sellMax = parseFloat($("#sellMax").val());
		var minAmount = parseFloat($("#minAmount").val());
		var message = $("#sellConfirm").html();
		var price = parseFloat(obj.cleanNumber($('#sellPrice').val()));
		var sell = parseFloat(obj.cleanNumber($('#sellEstimate').val()));
		var pair = $("#sellPair").val().toUpperCase();
		var ticker = $("#sellTicker").val().toUpperCase();
		var remaining = $("#sellRemaining").val();
		
		// verify
		if (price > sellMax)
		{
			swal.fire({
				title: "Easy Buy &amp; Sell", 
				html: "You can not sell more than " + sellMax + " " + ticker + " on Easy Buy & Sell. Please use main exchange for large orders.<br /><a href='/'>Go to Exchange</a>", 
				type: "info",
				confirmButtonColor: "#6c757d"
			});
			
			return;
		}
		
		if (sell < minAmount)
		{
			swal.fire({
				title: "Easy Buy &amp; Sell", 
				html: "You can not sell less than " + minAmount + " " + pair + " on Easy Buy & Sell. Please use main exchange for large orders.<br /><a href='/'>Go to Exchange</a>", 
				type: "info",
				confirmButtonColor: "#6c757d"
			});
			
			return;
		}
		
		if (remaining > 0)
		{
			$("#sellEstimate").val(0);
			$("#sellEstimateView span ").html(0);
			
			$("#sellAmountCoins").val(0);
			$("#sellCoinPrice").val(0);
			
			$("#sellPrice").val(0);
			
			swal.fire({
				title: "Easy Buy &amp; Sell", 
				html: "Insufficient liquidity to full order. Please use main exchange for large orders.<br /><a href='/'>Go to Exchange</a>", 
				type: "info",
				confirmButtonColor: "#6c757d"
			});
			
			return;
		}
		
		$("#sellForm").submit();
	}
	
	checkMax(amount, max, pair, ticker)
	{
		if (amount > max)
		{
			swal.fire({
				title: "Easy Buy &amp; Sell", 
				html: "You can not buy more than " + max + " " + ticker + " on Easy Buy & Sell. Please use main exchange for large orders.<br /><a href='/'>Go to Exchange</a>", 
				type: "info",
				confirmButtonColor: "#6c757d"
			});
			return;
		}
	}
	
	/**
	 * Calculate estimates
	 */
	estimateBuy()
	{
		/*
		console.log("**************************************************");
		console.log("estimate buys");
		console.log("**************************************************");
		*/
		
		var obj = this;
		var marketOrderFee = parseFloat($("#marketOrderFee").val());
		var marketOrderSlipage = parseFloat($("#marketOrderSlipage").val());
		var remaining = parseFloat($("#buyPrice").val());
		var estimate = 0;
		var buyAmount = 0;
		var buyPrice = 0;
		
		var buyMax = parseFloat($("#buyMax").val());
		var pair = $("#buyPair").val().toUpperCase();
		var ticker = $("#buyTicker").val().toUpperCase();
		
		var rowPrice = 0;
		var rowAmount = 0;
		var rowTotal = 0;
		
		// loop through orderbook
		for (var i = 0; i < obj.sellOrderBook.length; ++i)
		{
			/*
			console.log("\n\n");
			console.log("--------------------------------------------------");
			console.log("remaining " + remaining);
			console.log("--------------------------------------------------");
			*/
						
			rowPrice = parseFloat(obj.sellOrderBook[i]["coin_price"]);
			//console.log("price " + rowPrice);
			
			rowAmount = parseFloat(obj.sellOrderBook[i]["amount_coins"]);
			//console.log("amount " + rowAmount);
			
			rowTotal = (parseFloat(obj.sellOrderBook[i]["amount_coins"]) * parseFloat(obj.sellOrderBook[i]["coin_price"]));
			//console.log("total " + rowTotal);

			// 1 sell matches request
			if (rowTotal == remaining)
			{
				/*
				console.log("--------------------------------------------------");
				console.log("exact");
				console.log("--------------------------------------------------");
				*/
				
				estimate += rowAmount;
				//console.log("est " + estimate);
				
				buyAmount = estimate;
				buyPrice = rowPrice;
				
				remaining = 0;
				
				obj.checkMax(estimate, buyMax, pair, ticker);
				break;
			}
			// 1 sell is more than needed to match request
			else if (rowTotal > remaining)
			{
				/*
				console.log("--------------------------------------------------");
				console.log("1 sell");
				console.log("--------------------------------------------------");
				*/
				
				estimate += remaining / rowPrice;
				//console.log("est " + estimate);
				
				buyAmount = estimate;
				buyPrice = rowPrice;
				
				remaining = 0;
				
				obj.checkMax(estimate, buyMax, pair, ticker);
				break;
			}
			// 1 sell is not enough to match request
			else if (rowTotal < remaining)
			{
				/*
				console.log("--------------------------------------------------");
				console.log("more sells");
				console.log("--------------------------------------------------");
				*/

				estimate += rowAmount;
				//console.log("est " + estimate);
				
				//console.log("remaining before " + remaining);
				remaining -= rowTotal;
				//console.log("remaining after " + remaining);
				
				obj.checkMax(estimate, buyMax, pair, ticker);
			}
		}
		
		// deduct fee
		var estimateNoFee = estimate;
		estimate = estimate - ((estimate / 100) * marketOrderFee);
		/*
		console.log("est after fee " + estimate);
		console.log("--------------------------------------------------");
		*/
		
		// display estimate
		$("#buyRemaining").val(remaining);
		$("#buyEstimate").val(estimateNoFee);
		$("#buyEstimateView span").html(estimate.toFixed(8));
		
		// save limit details
		var trimmedAmount = obj.trimEstimate(buyAmount, 8);
		$("#buyAmountCoins").val(trimmedAmount);
		$("#buyCoinPrice").val(buyPrice);
	}
	
	estimateSell()
	{
		/*
		console.log("**************************************************");
		console.log("estimate sells");
		console.log("**************************************************");
		*/
		
		var obj = this;
		var marketOrderFee = parseFloat($("#marketOrderFee").val());
		var marketOrderSlipage = parseFloat($("#marketOrderSlipage").val());
		var remaining = parseFloat($("#sellPrice").val());
		var estimate = 0;
		var sellAmount = parseFloat($("#sellPrice").val());
		var sellPrice = 0;
		
		var sellMax = parseFloat($("#sellMax").val());
		var pair = $("#sellPair").val().toUpperCase();
		var ticker = $("#sellTicker").val().toUpperCase();
		
		var rowPrice = 0;
		var rowAmount = 0;
		var rowTotal = 0;
		
		// verify
		obj.checkMax(sellAmount, sellMax, pair, ticker);
		
		// loop through orderbook
		for (var i = 0; i < obj.buyOrderBook.length; ++i)
		{
			/*
			console.log("\n\n");
			console.log("--------------------------------------------------");
			console.log("remaining " + remaining);
			console.log("--------------------------------------------------");
			*/
			
			rowPrice = parseFloat(obj.buyOrderBook[i]["coin_price"]);
			//console.log("price " + rowPrice);
			
			rowAmount = parseFloat(obj.buyOrderBook[i]["amount_coins"]);
			//console.log("amount " + rowAmount);
			
			rowTotal = parseFloat(obj.buyOrderBook[i]["amount_coins"]) * parseFloat(obj.buyOrderBook[i]["coin_price"]);
			//console.log("total " + rowTotal);
			
			// 1 buy matches request
			if (rowAmount == remaining)
			{
				/*
				console.log("--------------------------------------------------");
				console.log("exact");
				console.log("--------------------------------------------------");
				*/
				
				estimate += rowTotal;
				//console.log("est " + estimate);
				
				sellPrice = rowPrice;
				
				remaining = 0;
				break;
			}
			// 1 buy is more than needed to match request
			else if (rowAmount > remaining)
			{
				/*
				console.log("--------------------------------------------------");
				console.log("1 buy");
				console.log("--------------------------------------------------");
				*/
				
				estimate += rowPrice * remaining;
				//console.log("est " + estimate);
				
				sellPrice = rowPrice;
				
				remaining = 0;
				break;
			}
			// 1 buy is not enough to match request
			else if (rowAmount < remaining)
			{
				/*
				console.log("--------------------------------------------------");
				console.log("more buys");
				console.log("--------------------------------------------------");
				*/
				
				estimate += rowPrice * rowAmount;
				//console.log("est " + estimate);
				
				//console.log("remaining before " + remaining);
				remaining -= rowAmount
				//console.log("remaining after " + remaining);
			}
		}
		
		// deduct fee
		estimate = estimate - ((estimate / 100) * marketOrderFee);
		
		/*
		console.log("est after fee " + estimate);
		console.log("--------------------------------------------------");
		*/
		
		// estimate
		$("#sellRemaining").val(remaining);
		$("#sellEstimate").val(parseFloat(estimate).toFixed(8));
		$("#sellEstimateView span ").html(parseFloat(estimate).toFixed(8));
		
		// save limit details
		$("#sellAmountCoins").val(parseFloat(sellAmount).toFixed(8));
		$("#sellCoinPrice").val(parseFloat(sellPrice).toFixed(8));
	}
	
	/**
	 * Remove unwanted characters from numeric input
	 */
	cleanNumber(value)
	{
		// convert to string
		value = "" + value;
		
		// max length
		var max = (value.indexOf(".") > -1) ? 21 : 12;
		var cleaned = (value.length >= max) ? value.substr(0, max) : value;
		
		// replace non numeric or period chars
		cleaned = cleaned.replace(/[^0-9\.]+/g, "");
		
		// format number
		if (cleaned.indexOf(".") > -1)
		{
			var parts = cleaned.split(".");
			cleaned = parts[0].match(/[0-9]{1,12}/) + "." + parts[1].match(/[0-9]{0,8}/);
		}
		
		// strip 0's in front
		cleaned = cleaned.replace(/^0+(?!\.|$)/, "");
		
		// replace empty string with 0
		cleaned = (cleaned != "") ? cleaned : 0;
		
		return cleaned;
	}
	
	/**
	 * trime estimation to avoid rounding issues
	 */
	trimEstimate(number, precision)
	{
		var result = number;
		
		if (("" + result).indexOf(".") !== -1)
		{
			var num = number.toString().split(".");
			result = num[0] + "." + num[1].substring(0, precision);
		}
		
		return result;
	}
	
	/**
	 * Highlight buttons
	 */
	buttonSelected(elm)
	{
		$(".btn").each(function()
		{
			$(this).removeClass("fresh-btn-hghlight");
		});
		
		$(elm).addClass("fresh-btn-hghlight");
	}
	
	/**
	 * List of coins
	 */
	coins()
	{
		var deferred = $.Deferred();
		var pair = $("#buyPair").val();
		var ticker = $("#buyTicker").val();
		
		$.ajax(
		{
			url: "/services/easy/coins.php",
			data: 
			{
				"pair": pair,
				"ticker": ticker
			},
			type: "POST",
			success: function(result) 
			{
				if (result.Error.Code == 0)
				{
					if (result.Data != "")
					{
						$("#buyEstimateView div").html(result.Data);
						$("#sellTickerView").html(result.Data);
						
						deferred.resolve();
					}
				}
				else
				{
					Misc.errorMessage("Error Reading Coins");
					deferred.reject();
				}
			},
			error: function(error) 
			{
				Misc.errorMessage("Error Reading Coins");
				deferred.reject();
			}
		});
		
		return deferred.promise();
	}
	
}