
class Verify
{

	constructor()
	{
		var obj = this;

		// step events
		switch($("#step").val())
		{
			case 2:
				step2();
				break;
			case 3:
				step3();
				break;
			default:
				step1();
				break;
		}
	}

	/**
	* Step 1 - authentication
	*
	* 1. redirect to remote service
	* 2. display details
	* 3. redirect to 1
	*/
	step1()
	{
		// check if details already exist
		// display if already exists
		//readDetails();

		$(document).on("click", "#authenticate", function()
		{
			obj.authenticate();
		});

		$(document).on("click", "#retry", function()
		{
			$("#details").hide();
			$("#blurb").show();
		});
	}

	/**
	* Step 2 - uploads
	*
	* 1. upload documents
	* 2. display success
	*/
	step2()
	{
		// upload events
		$(".uploadPreview").change(function()
		{
			var file = event.target.files[0];
			var docID = $(this).data("id");

			if (file)
			{
				$("#img" + docID).attr("src", URL.createObjectURL(file));
				$("#selected" + docID).val(1);
				$("#previewImage").removeClass("hide");
			}
		});

		$(".uploadDocument").click(function()
		{
			var docID = $(this).data("doc");

			// verify
			if ($("#selected" + docID).val() == 0)
			{
				swal.fire({
					title: "Error",
					html: "Please choose a file to upload",
					type: "error",
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					allowEscapeKey: false,
					closeOnClickOutside: false
				});

				return;
			}
		});
	}

	/**
	* Step 3 - declarations
	*
	* 1. save declarations
	* 2. submit final form
	* 3. run security checks
	*/
	step3()
	{
		// declaration drop downs
		$(".optionEMD").click(function()
		{
			$("#EMD").val($(this).data("value"));
			$("#ddEMD").text($(this).text());
		});

		$(".optionSoF").click(function()
		{
			$("#SoF").val($(this).data("value"));
			$("#ddSoF").text($(this).text());
		});

		$(".optionSoW").click(function()
		{
			$("#SoW").val($(this).data("value"));
			$("#ddSoW").text($(this).text());
		});

		$(".optionNoR").click(function()
		{
			$("#NoR").val($(this).data("value"));
			$("#ddNoR").text($(this).text());
		});





		// final confirmation
		$(document).on("click", "#confirm", function()
		{
			if (!$("#confirm_info").is(':checked'))
			{
				swal.fire({
					title: "Error",
					html: "Please confirm your declaration",
					type: "error",
					confirmButtonText: 'OK',
					allowOutsideClick: false,
					allowEscapeKey: false,
					closeOnClickOutside: false
				});
			}
			else
			{
				obj.checks();
				//obj.saveDeclarations($("#EMD").val(), $("#SoF").val(), $("#SoW").val(), $("#NoR").val());
			}
		});
	}

	authenticate()
	{
		var obj = this;

		$("#reportLoading").show();

		$.ajax(
		{
			url: "/services/verify/redirect.php",
			type: "POST",
			success: function(response)
			{
				if (response.Error.Code == 0)
				{
					location.href = response.Redirect;
				}
				else
				{
					Misc.errorMessage(response.Error.Message + " (" + response.Error.Code + ")");
				}

				$("#reportLoading").hide();
			},
			error: function()
			{
				Misc.errorMessage("Error Verifing Details");

				$("#reportLoading").hide();
			}
		});
	}

	showDetails(existing, docs)
	{
		var obj = this;

		// documents
		if ((docs.personal_number != "") &&
			(docs.first_name != "") &&
			(docs.last_name != ""))
		{
			$("#id_document").html(docs.personal_number);
			$("#firstname_document").html(docs.first_name);
			$("#lastname_document").html(docs.last_name);
		}
		else
		{
			// end early of docs missing
			$("#loading").hide();
			$("#error").show();
			return;
		}

		// exsiting
		if ((existing.id_number != "") &&
			(existing.first_name != "") &&
			(existing.last_name != ""))
		{
			$("#id_existing").html(existing.id_number);
			$("#firstname_existing").html(existing.first_name);
			$("#lastname_existing").html(existing.last_name);

			// don't allow confirm if existing & docs don't match
			if ((existing.id_number != docs.document_identifier) ||
				(existing.first_name != docs.first_name) ||
				(existing.last_name != docs.last_name))
			{
				$("#error-match").show();
				$("#loading").hide();
				return;
			}
		}

		// display
		$("#blurb").hide();
		$("#details").show();

		// enable button
		$("#confirm")
		.removeClass("disabled")
		.removeClass("btn-secondary")
		.addClass("btn-success");

		$("#loading").hide();
	}

	showDetailsError(error)
	{
		$("#error").html(error);
	}

	readDetails()
	{
		var obj = this;

		$.ajax(
		{
			url: "/services/verify/details.php",
			type: "POST",
			success: function(response)
			{
				if (response.Error.Code == 0)
				{
					obj.showDetails(response.Existing, response.Document);
				}
				else
				{
					obj.showDetailsError(reponse.Error.Message);

				}
			},
			error: function()
			{
				obj.showDetailsError("Error reading document details.");
			}
		 });
	}

	saveDeclarations(emd, sof, sow, nor)
	{
		var obj = this;

		$("#reportLoading").show();

		$.ajax({
			url: '/services/verify/declarations.php',
			type: 'POST',
			data: {
				"emd":emd,
				"sof":sof,
				"sow":sow,
				"nor":nor,
			},
			success: function(response)
			{
				if (response.Error.Code == 0)
				{
					// success
					console.log("data saved");
				}
				else
				{
					Misc.errorMessage(response.Error.Message + " (" + response.Error.Code + ")");
				}

				$("#reportLoading").hide();
			},
			error: function()
			{
				Misc.errorMessage("Error Verifing Details");

				$("#reportLoading").hide();
			}
		});
	}

	checks()
	{
		var obj = this;

		$("#reportLoading").show();

		$.ajax(
		{
			url: "/services/verify/checks.php",
			type: "POST",
			success: function(response)
			{
				if (response.Error.Code == 0)
				{
					// show success
					console.log("profile verified");
				}
				else
				{
					Misc.errorMessage(response.Error.Message + " (" + response.Error.Code + ")");
				}

				$("#reportLoading").hide();
			},
			error: function()
			{
				Misc.errorMessage("Error Verifing Details");

				$("#reportLoading").hide();
			}
		});
	}

}
